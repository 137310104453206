import React from 'react';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {Route, Routes} from 'react-router-dom';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#0f172a',
            paper: '#0f172a'
        },
        primary: {
            main: '#cbd5e1',
            dark: '#64748b'
        }
    }
});


function App() {

    return (
      <ThemeProvider theme={theme}>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/privacy" element={<PrivacyPolicy/>}/>
          </Routes>
      </ThemeProvider>
    );
}

export default App;
