import React, {FC} from "react";
import VirtualTryOnDemo from "./VirtualTryOnDemo";

const VirtualTryOnSection: FC<{ id: string }> = ({id}) => (
  <section id={id} className="flex snap-start lg:snap-center w-full px-5 shrink-0 px-20">

    <div className="max-w-max mx-auto lg:max-w-screen-2xl flex flex-col lg:grid lg:grid-cols-2 items-center lg:space-x-20">
          <div className="flex flex-col space-y-5">
              <h2 className="inline text-6xl 2xl:text-7xl font-serif text-slate-700">Virtual try-on</h2>
              <p className="text-sm xl:text-base text-slate-500">
                  Our innovative virtual try-on feature lets users preview how products will look on
                  themselves. This visual try-on tool will soon be seamlessly integrated across our entire
                  platform.
              </p>
              <p className="text-sm xl:text-base text-slate-500">
                  Our inspiration comes from observing customer behavior. Much like shoppers trying multiple
                  clothing items in a physical store's dressing room and keeping only the best-fitting ones,
                  many of our users add liked products to their cart. We're envisioning a shift from the
                  traditional shopping cart to a personalized fitting room. This transformation empowers
                  customers to assess how different clothing items harmonize together, ensuring a perfect
                  ensemble.
              </p>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                  <p
                    className="col-span-2 inline-flex items-center justify-between space-x-3 p-5 rounded-3xl bg-white">
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">43%</span>
                      <span className="text-sm text-slate-500">
                          of customers hesitate to complete purchases due to inability to try before they buy
                      </span>
                  </p>
                  <p className="inline-flex items-center space-x-3 p-5 bg-slate-100 rounded-3xl">
                      <span className="text-sm xl:text-base text-slate-500">Visualize products on yourself and make confident choices without leaving home.</span>
                  </p>
                  <p className="inline-flex items-center space-x-3 p-5 bg-slate-900 rounded-3xl">
                      <span
                        className="text-sm xl:text-base text-orange-50">Discover the convenience of a virtual dressing room that's open 24/7.</span>
                  </p>
                  <p
                    className="col-span-2 inline-flex items-center justify-between space-x-3 p-5 rounded-3xl bg-orange-50">
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">25%</span>
                      <span className="text-sm text-slate-500">
                          increase in online fashion sales reported by brands who are using virtual fitting rooms
                      </span>
                  </p>
              </div>
          </div>

          <div className="">
              <VirtualTryOnDemo/>
          </div>
      </div>

  </section>
)

export default VirtualTryOnSection
