import React, {FC, RefObject, useCallback, useEffect, useRef, useState} from "react";
import {
    PersonalisedSuggestion,
    Position,
    ProductFitConfig,
    suggestions,
    wardrobe
} from "../../../data/personalised-suggestions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Transition} from "@headlessui/react";

const PersonalisedSuggestionsDemo: FC = () => {

    const [activeWardrobeItem, setActiveWardrobeItem] = useState<{ id: number, image: string } | null>(null)
    const [activeSuggestion, setActiveSuggestion] = useState<PersonalisedSuggestion | null>(null)

    type ConnectorCoordinates = { x: number, y: number, cx: number, cy: number }

    const drawConnection = (productElement: HTMLDivElement, suggestionElement: HTMLDivElement, connectorElement: SVGPathElement) => {
        const wardrobeConnection: ConnectorCoordinates = {
            x: productElement.offsetLeft + productElement.offsetWidth / 2 - (productElement.parentElement as HTMLDivElement).scrollLeft,
            y: productElement.offsetTop + productElement.offsetHeight,
            cx: 0,
            cy: 1
        }

        const suggestionConnection: ConnectorCoordinates = {
            x: suggestionElement.offsetLeft + suggestionElement.offsetWidth / 2 - (suggestionElement.parentElement as HTMLDivElement).scrollLeft,
            y: suggestionElement.offsetTop,
            cx: 0,
            cy: -1
        }

        console.log(connectorElement)
        connectorElement.setAttribute('d', "M" +
          (wardrobeConnection.x) + "," + (wardrobeConnection.y) + " " +
          "C" +
          (wardrobeConnection.x + 20 * wardrobeConnection.cx) + "," + (wardrobeConnection.y + 20 * wardrobeConnection.cy) + " " +
          (suggestionConnection.x + 20 * suggestionConnection.cx) + "," + (suggestionConnection.y + 20 * suggestionConnection.cy) + " " +
          (suggestionConnection.x) + "," + (suggestionConnection.y));
    }

    useEffect(() => {
        setTimeout(() => setActiveWardrobeItem(wardrobe[0]), 1000)
    }, [])

    useEffect(() => {

        if (activeWardrobeItem == null) return

        const suggestion = suggestions.find((suggestion) => suggestion.wardrobe.includes(activeWardrobeItem.id));

        if (suggestion) {
            setActiveSuggestion(suggestion)
        }
    }, [activeWardrobeItem])

    useEffect(() => {

        if (!activeSuggestion) return

        const suggestionElement = document.querySelector(`[data-suggestion-id="${activeSuggestion.id}"]`) as HTMLDivElement
        const wardrobeContainer = document.getElementById('wardrobe-container') as HTMLDivElement
        const suggestionsContainer = document.getElementById('suggestions-container') as HTMLDivElement

        if (suggestionElement) {

            suggestionsContainer.scrollTo({left: suggestionElement.offsetLeft - suggestionElement.scrollWidth / 2 + 40, top: 0, behavior: 'smooth'})

            const products = document.querySelectorAll(activeSuggestion.wardrobe.map((item) => `[data-wardrobe-item-id="${item}"]`).join(', ')) as NodeListOf<HTMLDivElement>

            products.forEach((productElement) => {
                const id = productElement.getAttribute('data-wardrobe-item-id')

                if (id) {
                    const connectorElement: SVGPathElement = document.querySelector(`[data-connector-id="${id}"] path`) as SVGPathElement

                    if (connectorElement) {
                        const draw = () => drawConnection(productElement, suggestionElement, connectorElement)

                        wardrobeContainer && wardrobeContainer.addEventListener('scroll', draw)
                        suggestionsContainer && suggestionsContainer.addEventListener('scroll', draw)
                    }
                }
            })

        }

    }, [activeSuggestion])

    return (
      <>
          <img src="images/demo/personalised-suggestions/preview.webp" className="block lg:hidden"/>
          <div id="personalised-suggestions-demo" className="hidden lg:block relative w-full overflow-hidden px-5">

              {
                  wardrobe.map(({id}) => (
                    <div key={id} className={
                        'absolute h-full w-full pointer-events-none ' + (!activeSuggestion || !activeSuggestion.wardrobe.includes(id) ? 'hidden' : 'block')
                    }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" data-connector-id={id}>
                            <path fill="none" stroke="#fed7aa" strokeWidth="2"/>
                        </svg>
                    </div>
                  ))
              }


              <div className="relative">
                  <p className="text-slate-700 font-semibold mb-1.5">Your wardrobe</p>
                  <div id="wardrobe-container"
                       className="flex overflow-x-scroll overflow-y-hidden scrollbar-none space-x-3">
                      {
                          wardrobe.map((item) => (
                            <div
                              key={item.id}
                              data-wardrobe-item-id={item.id}
                              className={
                                  'pointer-events-auto wardrobe-item rounded-lg shadow-sm border ' +
                                  (activeSuggestion && activeSuggestion.wardrobe.includes(item.id) ? ' bg-white ' : ' bg-white/70 border-transparent') +
                                  (activeWardrobeItem && activeWardrobeItem.id == item.id ? ' border-orange-200' : ' border-transparent ')
                              }
                              onClick={() => setActiveWardrobeItem(item)}
                            >
                                <img src={item.image} className="h-24 aspect-square object-contain"/>
                            </div>
                          ))
                      }
                  </div>
              </div>

              <div id="suggestions-container"
                   className="flex overflow-x-scroll overflow-y-hidden scrollbar-none space-x-5 pb-5 -mb-5 mt-5 scroll-smooth">
                  {
                      suggestions.map((suggestion, idx) => (
                        <ProductSuggestion
                          {...suggestion}
                          active={!!(activeSuggestion && activeSuggestion.id == suggestion.id)}
                          key={idx}
                          setActive={() => {
                              setActiveWardrobeItem(null)
                              setActiveSuggestion(suggestion)
                          }}
                        />
                      ))
                  }
              </div>
          </div>
      </>
    )
}

const ProductSuggestion: FC<PersonalisedSuggestion & { active: boolean, setActive: () => void }> = ({
                                                                                                        id,
                                                                                                        image,
                                                                                                        description,
                                                                                                        wardrobe,
                                                                                                        active,
                                                                                                        setActive,
                                                                                                        products
                                                                                                    }) => {

    return (
      <div
        data-suggestion-id={id}
        className={
            'relative h-96 shrink-0 pointer-events-auto overflow-visible bg-white rounded-xl shadow-lg ' +
            (active ? 'grayscale-0 grid grid-cols-2 aspect-square' : 'grayscale aspect-[9/16]')
        }
        onClick={setActive}
      >
          <div
            className="w-full h-full rounded-xl shadow-md bg-cover bg-center bg-no-repeat z-[-1]"
            style={{
                backgroundImage: `url('${image}')`
            }}
          />

          {
              active && products.map((product, idx) => (
                <Badge {...product} key={idx}/>
              ))
          }

          <Transition
            show={active}
            enter="transition-all delay-100 duration-300 ease-out"
            enterFrom="transform scale-0 opacity-0"
            enterTo="transform scale-100 opacity-100"
          >
              <div className="px-5 py-5">
                  <p className="text-sm text-slate-700 italic">{description}</p>
              </div>
          </Transition>

      </div>
    )
}

const Badge: FC<ProductFitConfig> = ({image, price, prediction, size, dot, btn}) => {

    type ConnectorCoordinates = { x: number, y: number, cx: number, cy: number }

    const dotRef = useRef<HTMLDivElement>(null)
    const btnRef = useRef<HTMLDivElement>(null)
    const connectorRef = useRef<SVGPathElement>(null)

    const drawConnector = useCallback(() => {
        if (!dotRef.current || !btnRef.current || !connectorRef.current) return

        const dotConnection: ConnectorCoordinates = {
            x: dotRef.current.offsetLeft + dotRef.current.offsetWidth,
            y: dotRef.current.offsetTop + dotRef.current.offsetHeight / 2,
            cx: 1,
            cy: 1
        }
        const btnConnection: ConnectorCoordinates = {
            x: btnRef.current.offsetLeft,
            y: btnRef.current.offsetTop + btnRef.current.offsetHeight / 2,
            cx: 1,
            cy: 1
        }

        const setConnectorCoordinates = (position: Position, coordinates: ConnectorCoordinates, ref: RefObject<any>) => {
            switch (position) {
                case "bottom":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth / 2
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight
                    coordinates.cx = 0
                    coordinates.cy = 1
                    break;
                case "top":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth / 2
                    coordinates.y = ref.current.offsetTop
                    coordinates.cx = 0
                    coordinates.cy = -1
                    break;
                case "left":
                    coordinates.x = ref.current.offsetLeft
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight / 2
                    coordinates.cx = -1
                    coordinates.cy = 0
                    break;
                case "right":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight / 2
                    coordinates.cx = 1
                    coordinates.cy = 0
                    break;
            }
        }

        setConnectorCoordinates(dot.connect, dotConnection, dotRef)
        setConnectorCoordinates(btn.connect, btnConnection, btnRef)

        const dStr =
          "M" +
          (dotConnection.x) + "," + (dotConnection.y) + " " +
          "C" +
          (dotConnection.x + 50 * dotConnection.cx) + "," + (dotConnection.y + 50 * dotConnection.cy) + " " +
          (btnConnection.x + 50 * btnConnection.cx) + "," + (btnConnection.y + 50 * btnConnection.cy) + " " +
          (btnConnection.x) + "," + (btnConnection.y);

        connectorRef.current.setAttribute("d", dStr);

    }, [dotRef, btnRef, connectorRef]);

    useEffect(drawConnector, [])

    return (
      <>
          <div className="w-full h-full absolute pointer-events-none z-[100]">
              <div
                ref={dotRef}
                className="absolute h-3 w-3 bg-slate-200 rounded-full"
                style={{
                    top: `${dot.y}px`,
                    left: `${dot.x}px`,
                }}
              />
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                  <path
                    ref={connectorRef}
                    fill="none"
                    stroke="rgb(226, 232, 240)"
                    strokeWidth="2"
                  />
              </svg>
              <div
                ref={btnRef}
                className="pointer-events-auto absolute rounded-xl bg-white px-3 py-1.5 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-200 hover:bg-slate-50 transition-all duration-75 hover:scale-110"
                style={{
                    top: `${btn.y}px`,
                    left: `${btn.x}px`,
                }}
              >
                  <div className="relative">
                      <div
                        className="absolute top-[-10px] left-[-20px] inline-flex items-center gap-x-1.5 bg-slate-800 rounded-full px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-800">
                          <svg className="h-1.5 w-1.5 fill-teal-500" viewBox="0 0 6 6" aria-hidden="true">
                              <circle cx={3} cy={3} r={3}/>
                          </svg>
                          {size}
                          <small className="text-xs text-slate-300">{prediction} %</small>
                      </div>
                      <img src={image} className="h-20 w-20 object-contain"/>
                      <div className="w-full flex justify-between items-center z-[100]">
                          <span className="text-xs font-monospace">{price}</span>
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="h-3 w-3 text-slate-700"
                          />
                      </div>
                  </div>
              </div>
          </div>
      </>
    )
}

export default PersonalisedSuggestionsDemo
