import React, {FC, RefObject, useCallback, useEffect, useRef, useState} from "react";
import suggestions, {
    Position,
    Product,
    ProductSuggestionConfig
} from "../../../data/photo-reference-product-suggestions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Transition} from "@headlessui/react";

const SearchByPhotoDemo: FC = () => {

    const [badges, setBadges] = useState<ProductSuggestionConfig[]>(suggestions)

    const setOpen = (idx: number) => {
        setBadges((badges) => badges.map((badge, index) => {
            return {
                ...badge,
                open: index == idx ? !badge.open : false
            }
        }))
    }

    return (
      <>
          <img src="images/demo/search-by-reference/preview.webp" className="block lg:hidden"/>
          <div className="relative hidden lg:grid grid-cols-2 h-128 w-full overflow-hidden">
              <div className="relative">
                  <div
                    className="absolute w-full h-full rounded-xl shadow-md bg-cover bg-no-repeat bg-[url('../public/images/demo/search-by-reference/photo-reference.webp')]"/>
              </div>
              {
                  badges.map((badge, idx) => (
                    <Badge {...badge} toggleOpen={() => setOpen(idx)} key={idx}/>
                  ))
              }
          </div>
      </>
    )
}

const Badge: FC<ProductSuggestionConfig & { toggleOpen: () => void }> = ({
                                                                             title,
                                                                             dot,
                                                                             btn,
                                                                             active = false,
                                                                             open = false,
                                                                             toggleOpen,
                                                                             products = []
                                                                         }) => {

    type ConnectorCoordinates = { x: number, y: number, cx: number, cy: number }

    const dotRef = useRef<HTMLDivElement>(null)
    const btnRef = useRef<HTMLButtonElement>(null)
    const connectorRef = useRef<SVGPathElement>(null)

    const [btnPosition, setBtnPosition] = useState<{ x: number, y: number }>({x: btn.x, y: btn.y})

    const drawConnector = useCallback(() => {
        if (!dotRef.current || !btnRef.current || !connectorRef.current) return

        const dotConnection: ConnectorCoordinates = {
            x: dotRef.current.offsetLeft + dotRef.current.offsetWidth,
            y: dotRef.current.offsetTop + dotRef.current.offsetHeight / 2,
            cx: 1,
            cy: 1
        }
        const btnConnection: ConnectorCoordinates = {
            x: btnRef.current.offsetLeft,
            y: btnRef.current.offsetTop + btnRef.current.offsetHeight / 2,
            cx: 1,
            cy: 1
        }

        const setConnectorCoordinates = (position: Position, coordinates: ConnectorCoordinates, ref: RefObject<any>) => {
            switch (position) {
                case "bottom":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth / 2
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight
                    coordinates.cx = 0
                    coordinates.cy = 1
                    break;
                case "top":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth / 2
                    coordinates.y = ref.current.offsetTop
                    coordinates.cx = 0
                    coordinates.cy = -1
                    break;
                case "left":
                    coordinates.x = ref.current.offsetLeft
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight / 2
                    coordinates.cx = -1
                    coordinates.cy = 0
                    break;
                case "right":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight / 2
                    coordinates.cx = 1
                    coordinates.cy = 0
                    break;
            }
        }

        setConnectorCoordinates(open && !!dot.connect?.open ? dot.connect?.open : dot.connect.closed, dotConnection, dotRef)
        setConnectorCoordinates(open && !!btn.connect?.open ? btn.connect?.open : btn.connect.closed, btnConnection, btnRef)

        const dStr =
          "M" +
          (dotConnection.x) + "," + (dotConnection.y) + " " +
          "C" +
          (dotConnection.x + 50 * dotConnection.cx) + "," + (dotConnection.y + 50 * dotConnection.cy) + " " +
          (btnConnection.x + 50 * btnConnection.cx) + "," + (btnConnection.y + 50 * btnConnection.cy) + " " +
          (btnConnection.x) + "," + (btnConnection.y);

        connectorRef.current.setAttribute("d", dStr);

    }, [dotRef, btnRef, btnPosition, connectorRef, open]);

    useEffect(() => {

        if (open) {
            setBtnPosition({x: 400, y: 50})
        } else {
            setBtnPosition({x: btn.x, y: btn.y})
        }

        const id = setInterval(() => {
            drawConnector()
        }, 10)

        setTimeout(() => clearInterval(id), 1000)

    }, [open])

    return (
      <>
          <div className="w-full h-full absolute pointer-events-none">
              <div
                ref={dotRef}
                className="absolute h-3 w-3 bg-slate-200 rounded-full"
                style={{
                    top: `${dot.y}px`,
                    left: `${dot.x}px`,
                }}
              />
              {
                  active && (
                    <div
                      className="absolute h-3 w-3 bg-slate-200 rounded-full animate-ping"
                      style={{
                          top: `${dot.y}px`,
                          left: `${dot.x}px`,
                      }}
                    />
                  )
              }
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                  <path
                    ref={connectorRef}
                    fill="none"
                    stroke="rgb(226, 232, 240)"
                    strokeWidth="2"
                  />
              </svg>
              <button
                ref={btnRef}
                disabled={!active}
                className="pointer-events-auto absolute rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-slate-900 shadow-md ring-1 ring-inset ring-slate-200 hover:bg-slate-50 transition-all duration-75 hover:scale-110"
                style={{
                    top: `${btnPosition.y}px`,
                    left: `${btnPosition.x}px`,
                }}
                onClick={toggleOpen}
              >{title}</button>
          </div>
          {
              (active && open) && (
                <div
                  className="col-span-2 col-start-2 flex space-x-3 snap-x snap-mandatory overflow-x-scroll scrollbar-none items-start px-5 pt-[90px]">
                    {
                        products.map((product, idx) => (
                          <ProductCard {...product} key={idx}/>
                        ))
                    }
                </div>
              )
          }
      </>
    )
}

const ProductCard: FC<Product> = (product) => {

    const [addedToTryOn, setAddedToTryOn] = useState<boolean>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {

        setShowPopup(addedToTryOn)

        setTimeout(() => setShowPopup(false), 2000)

    }, [addedToTryOn])

    return (
      <div className="snap-center flex flex-col shrink-0 space-y-3">
          <div ref={ref}
               className="p-3 bg-white rounded-xl z-[100] shadow-md overflow-hidden hover:scale-105 transition-all duration-200">

              <img src={product.image}
                   className="w-36 aspect-[9/16] object-contain drop-shadow hover:scale-150 hover:drop-shadow-lg transition-all duration-200 z-[-1] relative"/>

              <div className="w-full flex justify-between items-center z-[100]">
                  <span className="font-monospace">{product.price}</span>
                  <button
                    className="flex items-center justify-center space-x-1 font-semibold px-2 py-1 h-8 rounded-full"
                    onClick={() => setAddedToTryOn((e) => !e)}
                  >

                      <FontAwesomeIcon
                        icon={addedToTryOn ? faCirclePlus : faPlus}
                        className={'h-4 w-4 ' + (addedToTryOn ? 'text-teal-500' : 'text-slate-700')}
                      />
                  </button>
              </div>
          </div>
          <Transition
            show={showPopup}
            as="div"
            className="text-center bg-white/50 text-slate-700 py-2 rounded-xl"
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
              <span className="text-sm">Added to fitting room</span>
          </Transition>
      </div>
    )
}

export default SearchByPhotoDemo
