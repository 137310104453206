import React, {FC, useEffect, useRef, useState} from "react";
import useConcaveBorderRadius from "../../hooks/useConcaveBorderRadius";
import logo from "../../logo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import SubscribeModal, {SubscribeModalDialog} from "../SubscribeModal";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {Transition} from "@headlessui/react";

const WelcomeSection: FC<{ id: string }> = ({id}) => {

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.defaultMuted = true;
        videoRef.current.muted = true;

        videoRef.current.play()
          .catch((reason) => {
              if (videoRef.current) {
                  videoRef.current.style.display = 'none';
              }
              console.log(reason);
          });

    }, [videoRef])

    const [windowWidth, setWindowWidth] = useState<number>(0)

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        if (window.location.hash) {
            const element = document.getElementById(window.location.hash.replace("#", ""))
            element && element.scrollIntoView();
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [centerBox, comingSoonBox] = useConcaveBorderRadius<HTMLDivElement>({
        gap: windowWidth > 1536 ? '0.75rem' : '0.5rem',
        radius: windowWidth > 1536 ? '5rem' : '2.5rem',
    })
    const [searchBox, searchByPhotoBox] = useConcaveBorderRadius<HTMLDivElement>({
        bgColor: '#fff7ed',
        gap: windowWidth > 1536 ? '0.75rem' : '0.5rem',
        radius: windowWidth > 1536 ? '5rem' : '2.5rem',
    })

    const [openSubscribeModal, setOpenSubscribeModal] = useState<boolean>(false)

    return (
      <section id={id} className="flex snap-start lg:snap-center w-full px-5 shrink-0 px-20">

          <div className="hidden max-w-max mx-auto lg:max-w-screen-2xl lg:grid grid-cols-4 grid-rows-3 gap-2 2xl:gap-3 rounded-5xl">
              {/* Logo */}
              <div
                className="flex items-center justify-center relative overflow-hidden z-[100] rounded-4xl 2xl:rounded-5xl bg-white">
                  <video ref={videoRef} autoPlay loop playsInline muted controls={false}
                         poster="images/background/animation-placeholder.webp"
                         className="background-video">
                      <source src="images/background/animation-footer.webm" type="video/webm"/>
                      <source src="images/background/animation-footer.mp4" type="video/mp4"/>
                  </video>
                  <img src={logo} alt="Indivog Logo" className="h-12 2xl:h-24"/>
              </div>
              {/* Search */}
              <div ref={searchBox}
                   className="flex flex-col col-span-2 space-y-3 justify-center relative bg-orange-50 rounded-t-4xl rounded-br-4xl 2xl:rounded-t-5xl 2xl:rounded-br-5xl p-8 2xl:p-14">
                  <div className="flex items-end justify-between">
                      <div className="flex flex-col space-y-1.5 xl:space-y-3">
                          <h2 className="text-3xl xl:text-5xl font-serif text-orange-400">Supercharged search</h2>
                          <p className="text-sm h-2xl:text-base text-slate-500">
                              Our solution involves harnessing Al's capabilities <br/>
                              to implement filters based on complex user queries.
                          </p>
                      </div>
                      <a href="#search"
                         className="flex items-center rounded-full aspect-square px-3 py-2 2xl:px-5 2xl:py-4 bg-orange-400 text-white">
                          <FontAwesomeIcon icon={faArrowRight} className="h-6 w-6"/>
                      </a>
                  </div>
                  <p
                    className="inline-flex items-center space-x-3 p-3 bg-white xl:w-4/6 2xl:w-1/2 rounded-2xl 2xl:rounded-3xl">
                      <span className="-rotate-90 text-xs text-slate-500">only</span>
                      <span className="text-3xl h-2xl:text-5xl text-slate-700">16%</span>
                      <span className="text-xs text-slate-500">
                          of e-commerce platforms deliver a filtering experience that meet customer expectations
                      </span>
                  </p>
              </div>
              {/* Fit predictor */}
              <div
                className="flex flex-col justify-between row-span-2 bg-slate-100 rounded-4xl 2xl:rounded-5xl z-[100] p-8 2xl:p-14">
                  <div className="flex flex-col space-y-1.5 xl:space-y-5">
                      <h2 className="text-slate-700 text-5xl 2xl:text-7xl font-thin">
                          <span>Fit</span><br className=""/>
                          <span>predictor</span>
                      </h2>
                      <p className="text-sm h-2xl:text-base text-slate-500">
                          Ensures your selected garment complements your physique perfectly.
                      </p>
                  </div>
                  <div className="flex flex-col space-y-3">
                      <p
                        className="inline-flex flex-col justify-between space-y-1.5 xl:space-y-3 p-3 xl:p-5 rounded-xl xl:rounded-3xl bg-white">
                          <span className="text-3xl xl:text-5xl text-slate-700">43%</span>
                          <span className="text-xs text-slate-500">
                          of customers hesitate to complete purchases due to inability to try before they buy
                      </span>
                      </p>
                      <a
                        href="#fit-predictor"
                        className="inline-flex items-center justify-between space-x-3 border-t border-slate-300 text-base text-slate-500 py-1 mx-4">
                          <span>There's more</span>
                          <FontAwesomeIcon icon={faArrowRight} className="h-3 w-3"/>
                      </a>
                  </div>
              </div>
              {/* Search by photo */}
              <div ref={searchByPhotoBox}
                   className="flex flex-col justify-center 2xl:justify-between space-y-2 relative bg-orange-50 rounded-b-4xl rounded-tl-4xl 2xl:rounded-b-5xl 2xl:rounded-tl-5xl p-8 2xl:p-14">
                  <div className="flex items-center">
                      <h2 className="inline text-2xl 2xl:text-4xl text-slate-500">
                          <span className="font-serif italic">Photo-</span>
                          <span className="font-thin">based search</span>
                      </h2>
                      <a href="#search-by-photo"
                         className="flex items-center aspect-square border border-slate-200 rounded-full px-3 py-2 hover:bg-slate-100">
                          <FontAwesomeIcon icon={faChevronRight} className="text-slate-500 h-3 w-3 xl:h-5 xl:w-5"/>
                      </a>
                  </div>
                  <p className="inline 2xl:hidden text-xs xl:text-sm text-slate-500">
                      Discover comparable items in our database
                      and expand your wardrobe with clothing that
                      complements your desired style
                  </p>
                  <p className="hidden 2xl:inline text-sm text-slate-500">
                      <b className="font-medium">Find similar</b> - discover comparable items in our database
                      using AI.
                  </p>
                  <p className="hidden 2xl:inline text-sm text-slate-500">
                      <b className="font-medium">Complement style</b> - expand your wardrobe with clothing that
                      complements your desired style
                  </p>
              </div>
              {/* Main heading */}
              <div ref={centerBox}
                   className="flex items-center relative rounded-t-4xl rounded-bl-4xl 2xl:rounded-t-5xl 2xl:rounded-bl-5xl bg-white p-8 2xl:p-14 col-span-2">
                  <h1 className="inline font-serif text-5xl xl:text-7xl text-slate-700">
                      Redefining <a href="#vision" className="italic text-orange-400">fashion</a><br/>e-commerce
                  </h1>
              </div>
              {/* Personalisation */}
              <div className="flex flex-col justify-center p-8 2xl:p-14 rounded-4xl 2xl:rounded-5xl bg-slate-100">
                  <p className="inline-flex items-center space-x-3 mr-5">
                      <span className="inline-flex flex-col divide-y-2 divide-slate-500 text-3xl">
                          <span>4</span>
                          <span>5</span>
                      </span>
                      <span className="text-xs xl:text-sm text-slate-500">of customers note lack of personalisation in fashion e-commerce</span>
                  </p>
                  <h2
                    className="inline-flex justify-between items-center text-base text-slate-700 hover:text-slate-500 border-t py-2 mt-2 border-slate-200">
                      <a href="#personalised-suggestions" className="hover:text-slate-500">
                          Personalised suggestions
                      </a>
                      <FontAwesomeIcon icon={faArrowRight} className="h-3 w-3"/>
                  </h2>
                  <p className="hidden 2xl:inline text-sm text-slate-500">
                      With machine learning, we analyze user data, including wardrobe, style, and measurements,
                      to offer personalized outfit suggestions.
                  </p>
              </div>
              {/* Virtual try-on */}
              <div
                className="flex p-8 2xl:p-14 relative rounded-4xl 2xl:rounded-5xl bg-slate-900 col-span-2 z-[100]">
                  <div className="flex flex-col justify-between">
                      <div className="space-y-3">
                          <h2 className="font-serif text-3xl xl:text-5xl text-orange-50">Virtual try-on</h2>
                          <p className="max-w-xs xl:max-w-full text-xs xl:text-base text-orange-50">
                              Visualize how products will look on you with the magic of augmented reality. <br/>
                              Try different styles and make confident choices from the comfort of your own
                              space.
                          </p>
                      </div>
                      <a href="#virtual-try-on"
                         className="w-full inline-flex items-center space-x-3 text-orange-400">
                          <span className="text-base xl:text-lg">Read more</span>
                          <span className="grow border-b border-dashed border-orange-400"/>
                          <FontAwesomeIcon icon={faChevronRight} className="text-orange-400 h-3 w-3"/>
                      </a>
                  </div>
                  <img src="images/homepage/virtual-try-on-model.webp"
                       className="absolute h-64 2xl:h-96 object-contain bottom-0 right-0 drop-shadow-xl"/>
              </div>
              {/* Coming soon */}
              <div ref={comingSoonBox}
                   className="flex flex-col items-start justify-center space-y-1.5 2xl:space-y-5 relative rounded-b-4xl rounded-tr-4xl 2xl:rounded-b-5xl 2xl:rounded-tr-5xl bg-white p-8 2xl:p-14">
                  <h2 className="text-lg xl:text-3xl font-semibold text-slate-500">Coming soon!</h2>
                  <p className="text-xs xl:text-sm text-slate-500">
                      We're perfecting our innovative platform.
                      <br/>
                      Join our waitlist for an early update and get one month of free delivery!
                  </p>
                  <div className="inline-flex space-x-3">
                      <SubscribeModal/>
                      <a
                        href="https://instagram.com/_indivog_"
                        className="flex items-center px-2 py-1.5 xl:px-4 xl:py-2 border border-slate-500 rounded-full text-sm xl:text-base text-slate-500 hover:bg-slate-100 hover:text-slate-900 font-semibold focus:outline-none"
                      >
                          <FontAwesomeIcon icon={faInstagram} className="h-5 w-5"/>
                      </a>
                  </div>
              </div>
          </div>

          <div className="flex lg:hidden flex-col items-start py-10">
              <div
                className="flex items-center justify-center relative overflow-hidden z-[100] rounded-full bg-white px-4 py-2">
                  <video ref={videoRef} autoPlay loop playsInline muted controls={false}
                         poster="images/background/animation-placeholder.webp"
                         className="background-video">
                      <source src="images/background/animation-footer.webm" type="video/webm"/>
                      <source src="images/background/animation-footer.mp4" type="video/mp4"/>
                  </video>
                  <img src={logo} alt="Indivog Logo" className="h-8"/>
              </div>

              <h1 className="inline font-serif text-6xl text-slate-700 leading-8 my-10">
                  Redefining <a href="#vision" className="italic text-orange-400">fashion</a><br/>e-commerce
              </h1>

              <nav>
                  <ul className="flex flex-wrap">
                      <li className="px-4 py-3 mb-3 mr-3 bg-slate-100 text-lg rounded-full text-slate-700 font-semibold">
                          <a href="#search">Search</a>
                      </li>
                      <li className="px-4 py-3 mb-3 mr-3 bg-orange-100 text-lg rounded-full text-orange-400 font-semibold">
                          <a href="#search-by-photo">Search by Photo</a>
                      </li>
                      <li className="px-4 py-3 mb-3 mr-3 bg-slate-900 text-lg rounded-full text-orange-50 font-semibold">
                          <a href="#virtual-try-on">Virtual Try-on</a>
                      </li>
                      <li className="px-4 py-3 mb-3 mr-3 bg-white text-lg rounded-full text-slate-700 font-semibold">
                          <a href="#fit-predictor">Fit Predictor</a>
                      </li>
                      <li className="px-4 py-3 mb-3 mr-3 bg-slate-100 text-lg rounded-full text-slate-700 font-semibold">
                          <a href="#personalisation">Personalisation</a>
                      </li>
                      <li className="px-4 py-3 mb-3 mr-3 bg-white ring-2 ring-inset ring-slate-500 text-lg rounded-full text-slate-700 font-semibold">
                          <a role="button" onClick={() => setOpenSubscribeModal(true)}>Stay tuned!</a>
                          <Transition appear show={openSubscribeModal} as={'div'}>
                              <SubscribeModalDialog setOpen={setOpenSubscribeModal}/>
                          </Transition>
                      </li>
                      <li className="px-4 py-3 mb-3 mr-3 bg-white ring-2 ring-inset ring-slate-500 text-lg rounded-full text-slate-700 font-semibold flex items-center">
                          <a href="https://instagram.com/_indivog_" className=" inline-flex items-center justify-center">
                              <FontAwesomeIcon icon={faInstagram} className="h-6 w-6"/>
                          </a>
                      </li>
                  </ul>
              </nav>
          </div>
      </section>
    )
}

export default WelcomeSection
