import React, {FC} from "react";
import SubscribeModal from "../SubscribeModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faLinkedin, faLinkedinIn, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import config from "../../config";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const VisionSection: FC<{ id: string }> = ({id}) => (
  <footer id={id} className="snap-center w-full px-5 shrink-0 flex flex-col justify-center px-20 space-y-10 pb-10">

      <div className="flex flex-col lg:flex-row items-start lg:items-end justify-between space-y-3 lg:space-y-0 lg:space-x-10 max-w-max mx-auto lg:max-w-screen-2xl">
          <div className="flex flex-col space-y-5">
              <h2 className="inline text-6xl lg:text-7xl 2xl:text-9xl font-serif text-slate-700">Virtue Vision</h2>
              <p className="text-slate-500">
                  At Indivog, we strive to revolutionize the fashion e-commerce landscape, creating a
                  sustainable platform
                  <br/> that incorporates the advantages of traditional brick-and-mortar retail
              </p>
          </div>
          <div className="flex flex-col space-y-2">
              <div className="flex space-x-3 items-center">
                  <SubscribeModal/>
                  <a href={`mailto:${config.contact.email}`}
                     className="flex items-center px-2 py-2 xl:px-4 xl:py-2.5 border border-slate-500 rounded-full text-sm lg:text-base text-slate-500 hover:bg-slate-100 hover:text-slate-900 font-semibold focus:outline-none">
                      <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5"/>
                  </a>
                  <a href={config.socialMedia.instagram} target="_blank"
                     className="flex items-center px-2 py-1.5 xl:px-4 xl:py-2.5 border border-slate-500 rounded-full text-sm lg:text-base text-slate-500 hover:bg-slate-100 hover:text-slate-900 font-semibold focus:outline-none">
                      <FontAwesomeIcon icon={faInstagram} className="h-5 w-5"/>
                  </a>
                  <a href={config.socialMedia.tiktok} target="_blank"
                     className="flex items-center px-2 py-1.5 xl:px-4 xl:py-2.5 border border-slate-500 rounded-full text-sm lg:text-base text-slate-500 hover:bg-slate-100 hover:text-slate-900 font-semibold focus:outline-none">
                      <FontAwesomeIcon icon={faTiktok} className="h-5 w-5"/>
                  </a>
                  <a href={config.socialMedia.linkedin} target="_blank"
                     className="flex items-center px-2 py-1.5 xl:px-4 xl:py-2.5 border border-slate-500 rounded-full text-sm lg:text-base text-slate-500 hover:bg-slate-100 hover:text-slate-900 font-semibold focus:outline-none">
                      <FontAwesomeIcon icon={faLinkedinIn} className="h-5 w-5"/>
                  </a>
              </div>
              <div className="flex space-x-3 justify-end">
                  <p className="text-sm text-slate-400">Indivog © 2023 Coming soon!</p>
                  <Link to={'/privacy'} className="text-slate-400 text-sm hover:text-slate-500">Privacy</Link>
              </div>
          </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 grid-rows-2 gap-3 lg:gap-10">

          <div className="flex flex-col space-y-3 bg-slate-100 rounded-3xl p-5 lg:p-10">
              <h3 className="text-xl xl:text-3xl text-slate-500 font-semibold">Tailored personalization</h3>
              <p className="text-sm xl:text-base text-slate-500">
                  We prioritize individualized customer experiences by meticulously catering to each
                  client's
                  unique preferences. From aligning with their preferred style and body measurements to
                  seamlessly complementing their existing wardrobe, our goal is to deliver
                  hyper-personalized shopping satisfaction.
              </p>
          </div>

          <div className="flex flex-col space-y-3 bg-slate-100 rounded-3xl p-5 lg:p-10">
              <h3 className="text-xl xl:text-3xl text-3xl text-slate-500 font-semibold">Sustainable commitment</h3>
              <p className="text-sm xl:text-base text-slate-500">
                  Our unwavering dedication to sustainability, ethical clothing production, and slow
                  fashion
                  sets us apart. We meticulously curate a selection of high-quality, enduring clothing
                  made
                  from natural materials, ensuring that our customers enjoy both style and durability
                  while
                  significantly reducing return rates.
              </p>
          </div>

          <div className="flex flex-col space-y-3 bg-slate-100 rounded-3xl p-5 lg:p-10">
              <h3 className="text-xl xl:text-3xl text-3xl text-slate-500 font-semibold">Inclusivity & Diversity</h3>
              <p className="text-sm xl:text-base text-slate-500">
                  Our mission is to provide a diverse range of options that cater to every style and
                  need. We aspire to be your go-to destination, offering an inclusive array of products
                  that embrace various styles and accommodate diverse demands.
              </p>
          </div>

          <div className="flex flex-col space-y-3 bg-slate-100 rounded-3xl p-5 lg:p-10">
              <h3 className="text-xl xl:text-3xl text-3xl text-slate-500 font-semibold">Brand synergy</h3>
              <p className="text-sm xl:text-base text-slate-500">
                  Our meticulous brand partnerships go beyond selling clothes. We amplify each brand's
                  quality and identity, sharing their story and vision with customers, fostering a
                  deeper connection.
              </p>
          </div>
      </div>

  </footer>
)

export default VisionSection
