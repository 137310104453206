import React, {FC} from "react";
import FitPredictorDemo from "./FitPredictorDemo";

const FirPredictorCard: FC<{id: string}> = ({id}) => (
  <section id={id} className="flex snap-start lg:snap-center w-full px-5 shrink-0 px-20">

      <div className="max-w-max mx-auto lg:max-w-screen-2xl flex flex-col flex-col-reverse lg:grid lg:grid-cols-2 items-center space-y-5 lg:space-y-0 lg:space-x-10 xl:space-x-20">
          <div className="mt-3 lg:mt-0">
              <FitPredictorDemo/>
          </div>

          <div className="flex flex-col space-y-3 lg:space-y-5">
              <h2 className="inline text-6xl 2xl:text-7xl  font-serif text-slate-700">Fit predictor</h2>
              <p className="text-sm xl:text-base text-slate-500">
                  In today's market, various brands follow their unique sizing standards. This means that a
                  size 'S' in one brand may not necessarily be the same as a size 'S' in another, leading to
                  uncertainty in finding the right fit.
              </p>
              <p className="text-sm xl:text-base text-slate-500">
                  Our Fit Predictor feature is designed to eliminate this confusion for our customers. By
                  collecting precise body measurements from you and maintaining comprehensive sizing
                  information for each product, we utilize advanced AI to predict which size will best
                  complement your physique.
              </p>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5">
                  <p
                    className="col-span-2 inline-flex items-center justify-between space-x-3 p-5 rounded-3xl bg-white">
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">43%</span>
                      <span className="text-sm text-slate-500">
                                      of customers hesitate to complete purchases due to inability to try before they buy
                                  </span>
                  </p>
                  <p className="inline-flex items-center space-x-3 p-5 bg-slate-900 rounded-3xl">
                      <span className="text-sm xl:text-base text-orange-50">Say goodbye to sizing guesswork and hello to perfectly fitting garments.</span>
                  </p>
                  <p className="inline-flex items-center space-x-3 p-5 bg-slate-100 rounded-3xl">
                      <span className="text-sm xl:text-base text-slate-500">Step into a new era of e-commerce, where sizing is no longer a concern.</span>
                  </p>
                  <p
                    className="col-span-2 inline-flex items-center justify-between space-x-3 p-5 rounded-3xl bg-orange-50">
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">30%</span>
                      <span className="text-sm text-slate-500">
                                      reduction in return rates reported by businesses that implemented sizing prediction technology
                                  </span>
                  </p>
              </div>
          </div>
      </div>

  </section>
)


export default FirPredictorCard
