import {FC} from "react";
import logo from "../logo.svg"
import {Link} from "react-router-dom";

const PrivacyPolicy: FC = () => {

    return (
      <>
          <header className="max-w-5xl mx-auto flex justify-center py-5">
              <Link to="/">
                  <img src={logo} className="h-10"/>
              </Link>
          </header>
          <main className="max-w-3xl mx-auto flex flex-col space-y-6 pt-5 pb-10">
              <div className="flex flex-col space-y-2">
                  <h1 className="text-3xl font-serif text-slate-900">Privacy Policy</h1>
                  <small className="text-sm text-slate-500">
                      Effective Date: 8 October 2023
                  </small>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Introduction</h2>
                  <p className="text-sm text-slate-700">
                      Welcome to Indivog ("we," "us," "our"). At Indivog, we respect your privacy and are committed to
                      protecting your personal information. This Privacy Policy is designed to help you understand how
                      we
                      collect, use, disclose, and safeguard your personal information when you visit our website, <a href="https://www.indivog.com" className="hover:text-slate-900">www.indivog.com</a> ("Website").
                      By accessing or using our Website, you consent to the practices described in this Privacy Policy.
                      If
                      you do not agree to this Privacy Policy, please do not use our Website.
                  </p>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Information We Collect</h2>
                  <p className="text-sm text-slate-700">
                      We may collect the following types of personal information from you:
                  </p>
                  <ul className="list-disc pl-5 text-sm text-slate-700">
                      <li>
                          <b className="font-medium">Email</b>: We may collect your email address when you subscribe to our newsletter or contact us through our contact email address, <a href="mailto:hello@indivog.com" className="mailto:hello@indivog.com">hello@indivog.com</a>.
                      </li>
                      <li>
                          <b className="font-medium">First and Last Name</b>: We may collect your first and last name when you provide this information voluntarily, such as when you subscribe to our services or contact us.
                      </li>
                  </ul>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">How We Use Your Information</h2>
                  <p className="text-sm text-slate-700">
                      We may use your personal information for the following purposes:
                  </p>
                  <ul className="list-disc pl-5 text-sm text-slate-700">
                      <li>To provide you with information, products, or services you request from us.</li>
                      <li>To send you periodic emails, newsletters, updates, and promotional materials.</li>
                      <li>To improve our Website's content and functionality.</li>
                      <li>To analyze user behavior and trends through tools such as Google Analytics, Google Tag
                          Manager, and Google Ads.
                      </li>
                  </ul>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Cookies and Tracking Technologies</h2>
                  <p className="text-sm text-slate-700">
                      Our Website may use cookies and similar tracking technologies to enhance your browsing experience.
                      You can
                      manage your cookie preferences through your browser settings.
                  </p>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Disclosure of Your Information</h2>
                  <p className="text-sm text-slate-700">
                      We may share your personal information with third parties only in the following circumstances:
                  </p>
                  <ul className="list-disc pl-5 text-sm text-slate-700">
                      <li>With service providers who assist us in operating our Website and providing our
                          services.
                      </li>
                      <li>When required by law or legal process.</li>
                      <li>In connection with the sale, merger, or acquisition of all or part of our company.</li>
                  </ul>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Your Choices and Rights</h2>
                  <p className="text-sm text-slate-700">
                      You have the right to:
                  </p>
                  <ul className="list-disc pl-5 text-sm text-slate-700">
                      <li>Access, update, or delete your personal information.</li>
                      <li>Opt-out of marketing communications at any time.</li>
                      <li>Object to the processing of your personal information for certain purposes.</li>
                  </ul>
                  <p className="text-sm text-slate-700">
                      To exercise these rights or for any privacy-related inquiries, please contact us at
                      <a href="mailto:hello@indivog.com" className="mailto:hello@indivog.com">hello@indivog.com</a>.
                  </p>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Security</h2>
                  <p className="text-sm text-slate-700">
                      We take reasonable measures to protect your personal information from unauthorized access,
                      disclosure,
                      alteration, or destruction. However, no method of transmission over the internet or electronic
                      storage is
                      completely secure. Therefore, we cannot guarantee absolute security.
                  </p>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Changes to this Privacy Policy</h2>
                  <p className="text-sm text-slate-700">
                      We may update this Privacy Policy to reflect changes in our practices or for other operational,
                      legal, or
                      regulatory reasons. We will notify you of any material changes through our Website or by email.
                  </p>
              </div>
              <div className="">
                  <h2 className="font-medium text-lg text-slate-900">Contact Us</h2>
                  <p className="text-sm text-slate-700">
                      If you have any questions, concerns, or comments about this Privacy Policy or our data practices,
                      please contact us at:

                      Indivog
                      Email: <a href="mailto:hello@indivog.com" className="mailto:hello@indivog.com">hello@indivog.com</a>

                  </p>
              </div>

          </main>
      </>
    )
}

export default PrivacyPolicy
