export type Position = 'bottom' | 'top' | 'left' | 'right'

export type ProductFitConfig = {
    image: string,
    price: string,
    prediction: number,
    size: string,
    dot: {
        x: number,
        y: number,
        connect: Position
    }
    btn: {
        x: number,
        y: number,
        connect: Position
    }
}

export type PersonalisedSuggestion = {
    id: number
    image: string
    description: string
    wardrobe: number[]
    products: ProductFitConfig[]
}

export const wardrobe: Array<{id: number, image: string}> = [
    {id: 1, image: 'images/demo/personalised-suggestions/wardrobe/blouses/1.webp'},
    {id: 2, image: 'images/demo/personalised-suggestions/wardrobe/blouses/2.webp'},
    {id: 3, image: 'images/demo/personalised-suggestions/wardrobe/t-shirts/1.webp'},
    {id: 4, image: 'images/demo/personalised-suggestions/wardrobe/trousers/1.webp'},
    {id: 5, image: 'images/demo/personalised-suggestions/wardrobe/trousers/2.webp'},
]

export const suggestions: PersonalisedSuggestion[] = [
    {
        id: 1,
        image:'images/demo/personalised-suggestions/suggestions/1/model.webp',
        wardrobe: [1,4],
        description: '"This jacket is perfect for you. It\'s stylish and fits your body like a glove. Whether you\'re going for a formal look or something more casual, this jacket has you covered."',
        products: [
            {
                image: 'images/demo/personalised-suggestions/suggestions/1/product.webp',
                price: '$ 129.00',
                prediction: 98,
                size: 'S',
                dot: {
                    x: 120,
                    y: 100,
                    connect: 'bottom'
                },
                btn: {
                    x: 160,
                    y: 220,
                    connect: 'top'
                }
            }
        ]
    },
    {
        id: 2,
        image:'images/demo/personalised-suggestions/suggestions/2/model.webp',
        description: '"These trousers are a great choice. They\'re modern, comfy, and will flatter your figure beautifully. You can dress them up or down, and they\'ll always make you look fantastic."',
        wardrobe: [1],
        products: [
            {
                image: 'images/demo/personalised-suggestions/suggestions/2/product.webp',
                price: '$ 129.00',
                prediction: 98,
                size: 'S',
                dot: {
                    x: 120,
                    y: 230,
                    connect: 'right'
                },
                btn: {
                    x: 180,
                    y: 230,
                    connect: 'left'
                }
            }
        ]
    },
    {
        id: 3,
        image:'images/demo/personalised-suggestions/suggestions/3/model.webp',
        description: '"This cropped jacket is an excellent pick for you. Its combination of style and warmth makes it a standout choice. With the down and feathers filling, it keeps you cozy in style, while the cropped design adds a fashionable edge to your look."',
        wardrobe: [2,3,4],
        products: [
            {
                image: 'images/demo/personalised-suggestions/suggestions/3/product.webp',
                price: '$ 129.00',
                prediction: 98,
                size: 'S',
                dot: {
                    x: 120,
                    y: 100,
                    connect: 'bottom'
                },
                btn: {
                    x: -30,
                    y: 220,
                    connect: 'top'
                }
            }
        ]
    },
    {
        id: 4,
        image:'images/demo/personalised-suggestions/suggestions/4/model.webp',
        description: '"This bomber jacket is a great choice for you. It embodies a cool and casual style that suits you perfectly. With its comfortable fit and trendy design, it\'s the ideal jacket to add a touch of urban flair to your outfits."',
        wardrobe: [3,4],
        products: [
            {
                image: 'images/demo/personalised-suggestions/suggestions/4/product.webp',
                price: '$ 129.00',
                prediction: 98,
                size: 'S',
                dot: {
                    x: 120,
                    y: 100,
                    connect: 'bottom'
                },
                btn: {
                    x: 120,
                    y: 230,
                    connect: 'top'
                }
            }
        ]
    },
    {
        id: 5,
        image:'images/demo/personalised-suggestions/suggestions/5/model.webp',
        description: '"This casual coat is a fantastic pick for you. It effortlessly complements your relaxed style while providing a comfortable fit. It\'s the perfect outerwear to complete your laid-back, yet stylish, look."',
        wardrobe: [5],
        products: [
            {
                image: 'images/demo/personalised-suggestions/suggestions/5/product.webp',
                price: '$ 129.00',
                prediction: 98,
                size: 'S',
                dot: {
                    x: 120,
                    y: 100,
                    connect: 'bottom'
                },
                btn: {
                    x: -30,
                    y: 220,
                    connect: 'top'
                }
            }
        ]
    }
]
