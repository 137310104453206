import {RefObject, useEffect, useRef} from "react";
import {generateUniqueClassName} from "../helpers/classNames";

interface UseConcaveBorderRadiusProps<T extends HTMLElement> {
    ref1?: RefObject<T>,
    ref2?: RefObject<T>,
    baseColor?: string
    bgColor?: string
    gap?: string
    radius?: string
}

function useConcaveBorderRadius<T extends HTMLElement>({ref1, ref2, baseColor = '#f8fafc', bgColor = '#ffffff', gap = '0rem', radius = '5rem'}: UseConcaveBorderRadiusProps<T>) {

    let newRef1 = useRef<T>(null)
    let newRef2 = useRef<T>(null)

    if (ref1) newRef1 = ref1
    if (ref2) newRef2 = ref2

    const styles: {
        [key in 'br' | 'bl' | 'tr' | 'tl']: { before: string, after: string }
    } = {
        'tl': {
            'before': `
                width: ${radius};
                height: ${radius};
                left: -${radius};
                top: calc(-${gap});
            `,
            'after': `
                width: ${radius};
                height: ${radius};
                border-radius: 0 100% 0 0 ;
                left: -${radius};
                top: calc(-${gap});
            `
        },
        'tr': {
            'before': `
                width: ${radius};
                height: ${radius};
                left: 100%;
                top: calc(-${gap});
            `,
            'after': `
                width: ${radius};
                height: ${radius};
                border-radius: 100% 0 0 0 ;
                left: 100%;
                top: calc(-${gap});
            `
        },
        'bl': {
            'before': `
                width: ${radius};
                height: ${radius};
                left: -${radius};
                bottom: calc(-${gap});
            `,
            'after': `
                width: ${radius};
                height: ${radius};
                border-radius: 0 0 100% 0 ;
                left: -${radius};
                bottom: calc(-${gap});
            `
        },
        'br': {
            'before': `
                width: ${radius};
                height: ${radius};
                left: 100%;
                bottom: calc(-${gap});
            `,
            'after': `
                width: ${radius};
                height: ${radius};
                border-radius: 0 0 0 100%;
                left: 100%;
                bottom: calc(-${gap});
            `
        },
    }

    useEffect(() => {

        if (newRef1.current && newRef2.current) {
            let styleTag = document.getElementById('concave-border-radius')
            if (!styleTag) styleTag = document.createElement('style');

            let ref1Position: 'br' | 'bl' | 'tr' | 'tl', ref2Position: 'br' | 'bl' | 'tr' | 'tl';
            const [className1, className2] = [generateUniqueClassName('br-concave'), generateUniqueClassName('br-concave')]

            if (newRef1.current.offsetLeft < newRef2.current.offsetLeft) {
                if (newRef1.current.offsetTop < newRef2.current.offsetTop) {
                    ref1Position = 'br'
                    ref2Position = 'tl'
                } else {
                    ref1Position = 'tr'
                    ref2Position = 'bl'
                }
            } else {
                if (newRef1.current.offsetTop < newRef2.current.offsetTop) {
                    ref1Position = 'bl'
                    ref2Position = 'tr'
                } else {
                    ref1Position = 'br'
                    ref2Position = 'tl'
                }
            }

            styleTag.innerHTML = `
                .${className1}::before {
                    content:'';
                    position:absolute;
                    background: ${bgColor};
                    ${styles[ref1Position].before}
                }
                .${className1}::after {
                    content:'';
                    display:block;
                    position:absolute;
                    background: ${baseColor};
                    ${styles[ref1Position].after}
                }
                .${className2}::before {
                    content:'';
                    position:absolute;
                    background: ${bgColor};
                    ${styles[ref2Position].before}
                }
                .${className2}::after {
                    content:'';
                    display:block;
                    position:absolute;
                    background: ${baseColor};
                    ${styles[ref2Position].after}
                }
            `

            document.head.appendChild(styleTag);

            newRef1.current.classList.add(className1);
            newRef2.current.classList.add(className2);
        }

    }, [ref1, ref2, radius, gap, bgColor, baseColor])

    return [newRef1, newRef2]
}

export default useConcaveBorderRadius
