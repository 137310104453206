export type Filter = {
    label: string,
    active?: boolean,
    values: Array<{
        label: string
        active?: boolean
    }>
}

export type Product = {
    image: string
    price: string
}

export type Query = {
    query: string
    suggestions: string[],
    filters: Filter[]
    products: Product[]
}

const searchQueries: Query[] = [
    {
        query: 'Men\'s plain t-shirt regular fit round neck',
        suggestions: ['white', 'black'],
        filters: [
            {
                label: 'Fit',
                active: true,
                values: [
                    {label: 'Any'},
                    {label: 'Regular', active: true},
                    {label: 'Slim Fit'},
                    {label: 'Relaxed'},
                    {label: 'Oversize'},
                ]
            },
            {
                label: 'Pattern',
                active: true,
                values: [
                    {label: 'Any'},
                    {label: 'Plain', active: true},
                    {label: 'Print'},
                    {label: 'Graphic'},
                    {label: 'Logo'},
                    {label: 'Stripe'},
                    {label: 'Floral'},
                    {label: 'Geometric'},
                ]
            },
            {
                label: 'Neckline',
                active: true,
                values: [
                    {label: 'Any'},
                    {label: 'Collared'},
                    {label: 'V-Neck'},
                    {label: 'Round Neck', active: true},
                    {label: 'Polo Neck'},
                    {label: 'Zip Neck'},
                ]
            },
            {
                label: 'Sleeve',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Short Sleeve'},
                    {label: 'Long Sleeve'},
                    {label: 'Sleeveless'},
                    {label: 'Half Sleeve'},
                ]
            },
            {
                label: 'Brand',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Brussels Beyond'},
                    {label: 'Paris Prism'},
                    {label: 'Rome Runway'},
                    {label: 'Prague Posh'},
                    {label: 'Madrid Mirage'},
                    {label: 'Zurich Zen'},
                ]
            }
        ],
        products: [
            {image: 'images/demo/search/t-shirts/1.webp', price: '$ 45.00'},
            {image: 'images/demo/search/t-shirts/2.webp', price: '$ 40.00'},
            {image: 'images/demo/search/t-shirts/3.webp', price: '$ 55.00'},
            {image: 'images/demo/search/t-shirts/4.webp', price: '$ 42.50'},
            {image: 'images/demo/search/t-shirts/5.webp', price: '$ 44.50'},
            {image: 'images/demo/search/t-shirts/6.webp', price: '$ 45.00'},
        ]
    },
    {
        query: 'Women\'s leather jacket',
        suggestions: ['black', 'brown', 'biker style'],
        products: [
            {image: 'images/demo/search/jackets/1.webp', price: '$ 215.00'},
            {image: 'images/demo/search/jackets/2.webp', price: '$ 200.00'},
            {image: 'images/demo/search/jackets/3.webp', price: '$ 195.00'},
            {image: 'images/demo/search/jackets/4.webp', price: '$ 199.99'},
            {image: 'images/demo/search/jackets/5.webp', price: '$ 190.00'},
            {image: 'images/demo/search/jackets/6.webp', price: '$ 219.00'},
        ],
        filters: [
            {
                label: 'Material',
                active: true,
                values: [
                    { label: 'Wool' },
                    { label: 'Leather', active: true },
                    { label: 'Down' },
                ]
            },
            {
                label: 'Style',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Biker Style' },
                    { label: 'Bomber' },
                    { label: 'Blazer' },
                ]
            },
            {
                label: 'Color',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Black' },
                    { label: 'Brown' },
                    { label: 'Red' },
                ]
            },
            {
                label: 'Length',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Short' },
                    { label: 'Mid' },
                    { label: 'Long' },
                    { label: 'Crop' },
                    { label: 'Knee' },
                ]
            },
            {
                label: 'Brand',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Brussels Beyond'},
                    {label: 'Paris Prism'},
                    {label: 'Rome Runway'},
                    {label: 'Prague Posh'},
                    {label: 'Madrid Mirage'},
                    {label: 'Zurich Zen'},
                ]
            }
        ],
    },
    {
        query: 'White basic tops',
        suggestions: ['round neck', 'short sleeve'],
        products: [
            {image: 'images/demo/search/tops/1.webp', price: '$ 75.00'},
            {image: 'images/demo/search/tops/2.webp', price: '$ 50.00'},
            {image: 'images/demo/search/tops/3.webp', price: '$ 95.00'},
            {image: 'images/demo/search/tops/4.webp', price: '$ 99.99'},
            {image: 'images/demo/search/tops/5.webp', price: '$ 90.00'},
            {image: 'images/demo/search/tops/6.webp', price: '$ 69.00'},
        ],
        filters: [
            {
                label: 'Color',
                active: true,
                values: [
                    {label: 'Any'},
                    { label: 'Black' },
                    { label: 'White', active: true },
                    { label: 'Gray' },
                    { label: 'Red' },
                ]
            },
            {
                label: 'Pattern',
                active: true,
                values: [
                    {label: 'Any'},
                    {label: 'Plain', active: true},
                    {label: 'Print'},
                    {label: 'Graphic'},
                    {label: 'Logo'},
                    {label: 'Stripe'},
                    {label: 'Floral'},
                    {label: 'Geometric'},
                ]
            },
            {
                label: 'Fit',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Regular' },
                    { label: 'Slim Fit' },
                    { label: 'Oversize' },
                ]
            },
            {
                label: 'Neckline',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Round Neck' },
                    { label: 'V-Neck' },
                    { label: 'Scoop Neck' },
                ]
            },
            {
                label: 'Sleeve',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Short Sleeve'},
                    {label: 'Long Sleeve'},
                    {label: 'Sleeveless'},
                    {label: 'Half Sleeve'},
                ]
            },
            {
                label: 'Brand',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Brussels Beyond'},
                    {label: 'Paris Prism'},
                    {label: 'Rome Runway'},
                    {label: 'Prague Posh'},
                    {label: 'Madrid Mirage'},
                    {label: 'Zurich Zen'},
                ]
            }
        ]
    },
    {
        query: 'Men\'s casual beige trousers',
        suggestions: ['regular fit', 'slim fit'],
        products: [
            {image: 'images/demo/search/trousers/1.webp', price: '$ 95.00'},
            {image: 'images/demo/search/trousers/2.webp', price: '$ 120.00'},
            {image: 'images/demo/search/trousers/3.webp', price: '$ 115.00'},
            {image: 'images/demo/search/trousers/4.webp', price: '$ 99.99'},
            {image: 'images/demo/search/trousers/5.webp', price: '$ 90.00'},
            {image: 'images/demo/search/trousers/6.webp', price: '$ 119.00'},
        ],
        filters: [
            {
                label: 'Color',
                active: true,
                values: [
                    {label: 'Any' },
                    { label: 'Beige', active: true },
                    { label: 'Brown' },
                    { label: 'Black' },
                    { label: 'White' },
                ]
            },
            {
                label: 'Style',
                active: true,
                values: [
                    { label: 'Any' },
                    { label: 'Chino', active: true },
                    { label: 'Cargo' },
                    { label: 'Tailored', active: true },
                    { label: 'Plain Front', active: true },
                ]
            },
            {
                label: 'Fit',
                values: [
                    {label: 'Any', active: true },
                    { label: 'Regular' },
                    { label: 'Slim Fit' },
                    { label: 'Straight Leg' },
                ]
            },
            {
                label: 'Material',
                values: [
                    { label: 'Any', active: true },
                    { label: 'Linen' },
                    { label: 'Cotton' },
                    { label: 'Wool' },
                    { label: 'Plain Front' },
                ]
            },
            {
                label: 'Brand',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Brussels Beyond'},
                    {label: 'Paris Prism'},
                    {label: 'Rome Runway'},
                    {label: 'Prague Posh'},
                    {label: 'Madrid Mirage'},
                    {label: 'Zurich Zen'},
                ]
            }
        ]
    },
    {
        query: 'Men\'s casual blue shirts',
        suggestions: ['white', 'blue'],
        products: [
            {image: 'images/demo/search/shirts/6.webp', price: '$ 115.00'},
            {image: 'images/demo/search/shirts/5.webp', price: '$ 120.00'},
            {image: 'images/demo/search/shirts/4.webp', price: '$ 135.00'},
            {image: 'images/demo/search/shirts/3.webp', price: '$ 150.00'},
            {image: 'images/demo/search/shirts/2.webp', price: '$ 125.00'},
            {image: 'images/demo/search/shirts/1.webp', price: '$ 119.00'},
        ],
        filters: [
            {
                label: 'Color',
                active: true,
                values: [
                    { label: 'White' },
                    { label: 'Blue', active: true },
                    { label: 'Pink' },
                    { label: 'Black' },
                ]
            },
            {
                label: 'Fit',
                values: [
                    { label: 'Regular' },
                    { label: 'Slim Fit', active: true },
                    { label: 'Classic Fit' },
                ]
            },
            {
                label: 'Pattern',
                values: [
                    { label: 'Any', active: true },
                    { label: 'Stripe' },
                    { label: 'Check' },
                    { label: 'Floral' },
                    { label: 'Tartan' },
                ]
            },
            {
                label: 'Sleeve',
                values: [
                    { label: 'Any', active: true },
                    { label: 'Long Sleeve' },
                    { label: 'Short Sleeve' },
                ]
            },
            {
                label: 'Material',
                values: [
                    { label: 'Any', active: true },
                    { label: 'Cotton' },
                    { label: 'Linen' },
                    { label: 'Synthetic' },
                ]
            },
            {
                label: 'Brand',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Brussels Beyond'},
                    {label: 'Paris Prism'},
                    {label: 'Rome Runway'},
                    {label: 'Prague Posh'},
                    {label: 'Madrid Mirage'},
                    {label: 'Zurich Zen'},
                ]
            }
        ]
    },
    {
        query: 'Men\'s slim fit jeans',
        suggestions: ['blue', 'black'],
        products: [
            {image: 'images/demo/search/jeans/1.webp', price: '$ 145.00'},
            {image: 'images/demo/search/jeans/2.webp', price: '$ 160.00'},
            {image: 'images/demo/search/jeans/3.webp', price: '$ 175.00'},
            {image: 'images/demo/search/jeans/4.webp', price: '$ 150.00'},
            {image: 'images/demo/search/jeans/5.webp', price: '$ 185.00'},
            {image: 'images/demo/search/jeans/6.webp', price: '$ 159.00'},
        ],
        filters: [
            {
                label: 'Fit',
                active: true,
                values: [
                    { label: 'Regular' },
                    { label: 'Slim Fit', active: true },
                    { label: 'Relaxed' },
                    { label: 'Skinny' },
                ]
            },
            {
                label: 'Color',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Blue' },
                    { label: 'Black' },
                    { label: 'Gray' },
                    { label: 'White' },
                ]
            },
            {
                label: 'Length',
                values: [
                    {label: 'Any', active: true},
                    { label: 'Regular' },
                    { label: 'Long' },
                    { label: 'X Long' },
                    { label: 'Short' },
                    { label: 'X Short' },
                ]
            },
            {
                label: 'Brand',
                values: [
                    {label: 'Any', active: true},
                    {label: 'Brussels Beyond'},
                    {label: 'Paris Prism'},
                    {label: 'Rome Runway'},
                    {label: 'Prague Posh'},
                    {label: 'Madrid Mirage'},
                    {label: 'Zurich Zen'},
                ]
            }
        ]
    }
]

export default searchQueries
