
export type Position = 'bottom' | 'top' | 'left' | 'right'

export type Product = { image: string, price: string }

export type ProductSuggestionConfig = {
    title: string
    dot: {
        x: number,
        y: number,
        connect: {
            open?: Position,
            closed: Position,
        }
    }
    btn: {
        x: number,
        y: number,
        connect: {
            open?: Position,
            closed: Position,
        }
    }
    active: boolean
    open?: boolean
    products?: Product[]
}

const suggestions: ProductSuggestionConfig[] = [
    {
        title: 'Blouse',
        dot: {
            x: 140,
            y: 150,
            connect: {
                open: 'right',
                closed: 'right',
            }
        },
        btn: {
            x: 250,
            y: 100,
            connect: {
                open: 'left',
                closed: 'left',
            }
        },
        active: true,
        open: true,
        products: [
            {image: 'images/demo/search-by-reference/blouses/5.webp', price: '$ 99.00'},
            {image: 'images/demo/search-by-reference/blouses/2.webp', price: '$ 120.00'},
            {image: 'images/demo/search-by-reference/blouses/3.webp', price: '$ 114.00'},
            {image: 'images/demo/search-by-reference/blouses/4.webp', price: '$ 125.00'},
            {image: 'images/demo/search-by-reference/blouses/5.webp', price: '$ 105.00'},
            {image: 'images/demo/search-by-reference/blouses/6.webp', price: '$ 118.00'},
        ]
    },
    {
        title: 'Trousers',
        dot: {
            x: 130,
            y: 380,
            connect: {
                open: 'top',
                closed: 'bottom',
            }
        },
        btn: {
            x: 30,
            y: 450,
            connect: {
                open: 'bottom',
                closed: 'top',
            }
        },
        active: true,
        open: false,
        products: [
            {image: 'images/demo/search-by-reference/trousers/5.webp', price: '$ 99.00'},
            {image: 'images/demo/search-by-reference/trousers/8.webp', price: '$ 118.00'},
            {image: 'images/demo/search-by-reference/trousers/2.webp', price: '$ 120.00'},
            {image: 'images/demo/search-by-reference/trousers/3.webp', price: '$ 114.00'},
            {image: 'images/demo/search-by-reference/trousers/4.webp', price: '$ 125.00'},
            {image: 'images/demo/search-by-reference/trousers/5.webp', price: '$ 105.00'},
            {image: 'images/demo/search-by-reference/trousers/6.webp', price: '$ 118.00'},
            {image: 'images/demo/search-by-reference/trousers/7.webp', price: '$ 118.00'},
        ]
    },
    {
        title: 'Bag',
        dot: {
            x: 160,
            y: 210,
            connect: {
                closed: 'right',
            }
        },
        btn: {
            x: 270,
            y: 250,
            connect: {
                closed: 'left',
            }
        },
        active: false
    },
    {
        title: 'Belt',
        dot: {
            x: 110,
            y: 260,
            connect: {
                closed: 'bottom',
            }
        },
        btn: {
            x: 180,
            y: 350,
            connect: {
                closed: 'top',
            }
        },
        active: false
    },
]

export default suggestions
