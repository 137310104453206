export default {
    aws: {
        region: process.env.REACT_APP_AWS_REGION || 'eu-central-1',
        accessKeyId: process.env.REACT_APP_AWS_PUBLIC_KEY || '',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY || ''
    },
    contact: {
        email: process.env.REACT_APP_CONTACT_EMAIL || 'hello@indivog.com',
    },
    socialMedia: {
        linkedin: process.env.REACT_APP_LINKEDIN_URL || 'https://www.linkedin.com',
        instagram: process.env.REACT_APP_INSTAGRAM_URL || 'https://www.linkedin.com',
        tiktok: process.env.REACT_APP_TIKTOK_URL || 'https://www.linkedin.com',
    }
}
