import React, {Dispatch, FC, Fragment, SetStateAction, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import lambdaClient from "../helpers/lambdaClient";
import {InvokeCommand} from "@aws-sdk/client-lambda";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Dialog, Transition} from "@headlessui/react";
import Cookies from 'universal-cookie';

export const WaitlistSubscribedCookieName = 'waitlist.subscribed'

const SubscribeForm: FC = () => {

    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState<boolean>(false)

    type Inputs = { email: string, name: string }

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<Inputs>()

    const onSubmit: SubmitHandler<Inputs> = async ({name, email}) => {
        setLoading(true)

        const params = {
            FunctionName: "saveSubscriber",
            Payload: JSON.stringify({name, email}),
        };

        const onSuccessCallback = () => {
            setSubmitted(true)
            setLoading(false)

            const cookies = new Cookies();
            cookies.set(WaitlistSubscribedCookieName, 1, { path: '/' });
        }

        lambdaClient
          .send(new InvokeCommand(params))
          .then(() => {
              if (gtag) {
                  gtag('event', 'conversion', {
                      'send_to': 'AW-849255025/WQlHCMvt1ukYEPG0-pQD',
                      'event_callback': onSuccessCallback
                  });
              } else {
                  onSuccessCallback()
              }
          })
          .catch((error) => {
              setError("Oops! An error occurred...")
              setLoading(false)
              console.log("Error", error);
          });
    }

    if (submitted) {
        return (
          <div className="rounded-xl bg-teal-100 px-4 py-2">
              <div className="flex">
                  <div className="flex-shrink-0">
                      <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-teal-400" aria-hidden="true"/>
                  </div>
                  <div className="ml-3">
                      <p className="text-sm font-medium text-teal-800">We're thrilled to have you subscribed! 🎉</p>
                  </div>
              </div>
          </div>
        )
    }

    return (
      <form
        className="flex flex-col space-y-3"
        onSubmit={handleSubmit(onSubmit)}
      >

          <div className="relative flex flex-col">
              <input
                className="px-4 py-2 rounded-xl text-base border border-slate-200 focus:outline-none focus:border-slate-400"
                placeholder="Name"
                {...register('name', {required: 'Name is required.'})}
              />
              {errors.name && <small className="absolute bg-orange-50 px-1.5 rounded-full bottom-[-8px] text-xs text-orange-400 ml-3">{errors.name.message}</small>}
          </div>
          <div className="relative flex flex-col">
              <input
                className="px-4 py-2 rounded-xl text-base border border-slate-200 focus:outline-none focus:border-slate-400"
                placeholder="Email"
                type="email"
                {...register('email', {
                    required: 'Email is required',
                    pattern: {
                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: 'Email has an incorrect format.'
                    }
                })}
              />
              {errors.email && <small className="absolute bg-orange-50 px-1.5 rounded-full bottom-[-8px] text-xs text-orange-400 ml-3">{errors.email.message}</small>}
          </div>

          {
              !!error && (
                <div className="rounded-xl bg-orange-100 px-4 py-2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="h-5 w-5 text-orange-400"
                                             aria-hidden="true"/>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-orange-800">{error}</p>
                        </div>
                    </div>
                </div>
              )
          }

          <button
            type="submit" disabled={loading}
            className="flex items-center justify-center space-x-3 px-4 py-2 bg-white rounded-xl text-base text-slate-700 hover:bg-slate-200 hover:text-slate-900 font-semibold disabled:bg-slate-300"
          >
              {
                  loading && <FontAwesomeIcon icon={faSpinner} className="h-4 w-4 animate-spin"/>
              }
              <span>Subscribe</span>
          </button>
      </form>
    )
}

export const SubscribeModalDialog: FC<{setOpen: Dispatch<SetStateAction<boolean>>}> = ({setOpen}) => {

    return (
      <Dialog as="div" className="relative z-[100]" onClose={() => setOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
              <div className="fixed inset-0 bg-black bg-opacity-25"/>
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                      <Dialog.Panel
                        className="w-full max-w-md transform overflow-hidden bg-slate-100 rounded-2xl p-6 text-left align-middle shadow-xl transition-all"
                      >
                          <Dialog.Title
                            as="h3"
                            className="text-2xl font-medium leading-6 text-gray-900"
                          >
                              Stay tuned!
                          </Dialog.Title>

                          <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                  Stay in the know and get a <b>free delivery</b> for a first month.
                                  Just drop your email below to unlock exclusive updates, news, and
                                  notifications.
                                  <br/>
                                  Be among the first to hear about our launch!
                              </p>
                          </div>

                          <div className="mt-4">
                              <SubscribeForm/>
                          </div>
                      </Dialog.Panel>
                  </Transition.Child>
              </div>
          </div>
      </Dialog>
    )
}

const SubscribeModal: FC = () => {

    const [open, setOpen] = useState<boolean>(false)

    return (
      <>
          <button
            className="px-2 py-1.5 xl:px-4 xl:py-2 border border-slate-500 rounded-full text-sm xl:text-base text-slate-500 hover:bg-slate-100 hover:text-slate-900 font-semibold focus:outline-none"
            onClick={() => setOpen(true)}
          >
              Stay tuned!
          </button>

          <Transition appear show={open} as={'div'}>
              <SubscribeModalDialog setOpen={setOpen}/>
          </Transition>
      </>
    )
}

export const SubscribeBanner: FC = () => {

    const [show, setShow] = useState<boolean>(false)

    const cookies = new Cookies();

    useEffect(() => {

        const timeout = setTimeout(() => {
            const cookie = cookies.get(WaitlistSubscribedCookieName);

            if (!cookie) {
                setShow(true)
            }
        }, 3000)

        return () => clearTimeout(timeout)

    }, [])

    const [openSubscribeModal, setOpenSubscribeModal] = useState<boolean>(false)

    return (
      <>
          <Transition appear show={show} as={Fragment}>
              <div className="fixed right-0 bottom-0 p-10 z-[100]">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                      <div
                        className=" w-full max-w-md transform overflow-hidden rounded-2xl bg-orange-400 p-6 text-left align-middle shadow-xl transition-all">
                          <h3 className="text-3xl font-medium leading-8 font-serif text-white">
                              Get a month of free delivery!
                          </h3>
                          <div className="mt-2">
                              <p className="text-sm text-orange-50">
                                  Join our waitlist now to get free delivery for your first month!
                              </p>
                          </div>

                          <div className="flex justify-between mt-4">
                              <button
                                onClick={() => {
                                    setOpenSubscribeModal(true)
                                    setShow(false)
                                }}
                                className="px-3 py-2 rounded-full bg-white font-semibold text-orange-400 hover:text-orange-300 focus:outline-none"
                              >
                                  Join the waitlist!
                              </button>
                              <button
                                onClick={() => setShow(false)}
                                className="text-orange-50 hover:text-orange-100 focus:outline-none"
                              >Close</button>
                          </div>
                      </div>
                  </Transition.Child>
              </div>
          </Transition>

          <Transition appear show={openSubscribeModal} as={'div'}>
              <SubscribeModalDialog setOpen={setOpenSubscribeModal}/>
          </Transition>
      </>
    )
}

export default SubscribeModal
