import React, {FC, RefObject, useCallback, useEffect, useRef, useState} from "react";
import Slider from 'react-input-slider';
import measurements, {
    Measurement,
    Position,
    ProductFitConfig,
    products
} from "../../../data/fit-predictor-measurements";

const FitPredictorDemo: FC = () => {


    return (
      <>
          <img src="images/demo/fit-predictor/preview.webp" className="block lg:hidden"/>
          <div className="relative hidden lg:grid grid-cols-2 h-128 w-full overflow-hidden">
              <div className="flex flex-col space-y-5 overflow-y-scroll scrollbar-none">
                  {
                      measurements.map((measurement, idx) => (
                        <MeasurementCard {...measurement} key={idx}/>
                      ))
                  }
              </div>
              <div className="relative ml-5">
                  <div className="absolute w-full h-full rounded-xl shadow-md bg-cover bg-center bg-no-repeat bg-[url('../public/images/demo/fit-predictor/model.webp')]"/>
                  {
                      products.map((product, idx) => (
                        <Badge {...product} key={idx}/>
                      ))
                  }
              </div>

          </div>
      </>
    )
}

const Badge: FC<ProductFitConfig> = ({image, prediction, size, dot, btn}) => {

    type ConnectorCoordinates = { x: number, y: number, cx: number, cy: number }

    const dotRef = useRef<HTMLDivElement>(null)
    const btnRef = useRef<HTMLDivElement>(null)
    const connectorRef = useRef<SVGPathElement>(null)

    const drawConnector = useCallback(() => {
        if (!dotRef.current || !btnRef.current || !connectorRef.current) return

        const dotConnection: ConnectorCoordinates = {
            x: dotRef.current.offsetLeft + dotRef.current.offsetWidth,
            y: dotRef.current.offsetTop + dotRef.current.offsetHeight / 2,
            cx: 1,
            cy: 1
        }
        const btnConnection: ConnectorCoordinates = {
            x: btnRef.current.offsetLeft,
            y: btnRef.current.offsetTop + btnRef.current.offsetHeight / 2,
            cx: 1,
            cy: 1
        }

        const setConnectorCoordinates = (position: Position, coordinates: ConnectorCoordinates, ref: RefObject<any>) => {
            switch (position) {
                case "bottom":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth / 2
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight
                    coordinates.cx = 0
                    coordinates.cy = 1
                    break;
                case "top":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth / 2
                    coordinates.y = ref.current.offsetTop
                    coordinates.cx = 0
                    coordinates.cy = -1
                    break;
                case "left":
                    coordinates.x = ref.current.offsetLeft
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight / 2
                    coordinates.cx = -1
                    coordinates.cy = 0
                    break;
                case "right":
                    coordinates.x = ref.current.offsetLeft + ref.current.offsetWidth
                    coordinates.y = ref.current.offsetTop + ref.current.offsetHeight / 2
                    coordinates.cx = 1
                    coordinates.cy = 0
                    break;
            }
        }

        setConnectorCoordinates(dot.connect, dotConnection, dotRef)
        setConnectorCoordinates(btn.connect, btnConnection, btnRef)

        const dStr =
          "M" +
          (dotConnection.x) + "," + (dotConnection.y) + " " +
          "C" +
          (dotConnection.x + 50 * dotConnection.cx) + "," + (dotConnection.y + 50 * dotConnection.cy) + " " +
          (btnConnection.x + 50 * btnConnection.cx) + "," + (btnConnection.y + 50 * btnConnection.cy) + " " +
          (btnConnection.x) + "," + (btnConnection.y);

        connectorRef.current.setAttribute("d", dStr);

    }, [dotRef, btnRef, connectorRef]);

    useEffect(drawConnector, [])

    return (
      <>
          <div className="w-full h-full absolute pointer-events-none">
              <div
                ref={dotRef}
                className="absolute h-3 w-3 bg-slate-200 rounded-full"
                style={{
                    top: `${dot.y}px`,
                    left: `${dot.x}px`,
                }}
              />
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                  <path
                    ref={connectorRef}
                    fill="none"
                    stroke="rgb(226, 232, 240)"
                    strokeWidth="2"
                  />
              </svg>
              <div
                ref={btnRef}
                className="pointer-events-auto absolute rounded-xl bg-white px-3 py-1.5 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-200 hover:bg-slate-50 transition-all duration-75 hover:scale-110"
                style={{
                    top: `${btn.y}px`,
                    left: `${btn.x}px`,
                }}
              >
                  <div className="relative">
                  <span
                    className="absolute top-[-10px] left-[-20px] inline-flex items-center gap-x-1.5 bg-slate-800 rounded-full px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-800">
                    <svg className="h-1.5 w-1.5 fill-teal-500" viewBox="0 0 6 6" aria-hidden="true">
                      <circle cx={3} cy={3} r={3}/>
                    </svg>
                      {size}
                  </span>
                      <img src={image} className="h-24 w-24"/>
                      <div className="flex flex-col items-center">
                          <span className="font-monospace font-normal text-lg">{prediction}%</span>
                          <span className="text-xs text-slate-400">match</span>
                      </div>
                  </div>
              </div>
          </div>
      </>
    )
}

const MeasurementCard: FC<Measurement> = ({title, image, minValue, maxValue, defaultValue}) => {

    const [value, setValue] = useState<number>(defaultValue)

    return (
      <div
        className="rounded-xl px-5 py-8 bg-white bg-contain shadow-sm bg-center bg-no-repeat grayscale hover:grayscale-0"
        style={{
            backgroundImage: `url(${image})`
        }}
      >
          <div className="flex flex-col">
              <h3 className="mb-1.5">{title}</h3>
              <span className="text-xl font-monospace mb-5">{value} CM</span>
              <Slider
                axis="x"
                styles={{
                    track: {
                        backgroundColor: '#cbd5e1'
                    },
                    active: {
                        backgroundColor: '#cbd5e1'
                    },
                    thumb: {
                        width: 20,
                        height: 20,
                        backgroundColor: '#fed7aa'
                    }
                }}
                x={value}
                xmin={minValue}
                xmax={maxValue}
                onChange={({x}) => setValue(parseFloat(x.toFixed(2)))}
              />
          </div>
      </div>
    )
}

export default FitPredictorDemo
