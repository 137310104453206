import WelcomeSection from "../components/sections/WelcomeSection";
import SearchSection from "../components/features/search/SearchSection";
import SearchByPhotoSection from "../components/features/search-by-photo/SearchByPhotoSection";
import VirtualTryOnSection from "../components/features/virtual-try-on/VirtualTryOnSection";
import FitPredictorCard from "../components/features/fit-predictor/FitPredictorScreen";
import PersonalisedSuggestionsScreen
    from "../components/features/personalised-suggestions/PersonalisedSuggestionsScreen";
import VisionSection from "../components/sections/VisionSection";
import {FC} from "react";

type ScreenType = Record<'id' | 'title', string> & {meta: Record<'title' | 'description', string>} & {Component: FC<any>}


const screens: ScreenType[] = [
    {
        id: 'home',
        title: 'Home',
        meta: {
            title: 'Indivog - AI-powered Fashion Marketplace',
            description: 'Discover the future of fashion shopping at Indivog. ' +
              'Explore curated collections, virtual try-ons, sustainable brands, and personalized style. ' +
              'Shop with confidence and embrace the latest trends. Elevate your fashion game today!'
        },
        Component: WelcomeSection
    },
    {
        id: 'search',
        title: 'Search',
        meta: {
            title: 'AI-Powered Search for Effortless Shopping',
            description: 'Revolutionize your fashion shopping with AI-driven tech. ' +
              'Easily discover personalized fashion items through advanced filters. ' +
              'Welcome to the future of online shopping!'
        },
        Component: SearchSection
    },
    {
        id: 'search-by-photo',
        title: 'Search by photo',
        meta: {
            title: 'Search by Photo - Find Fashion with Images',
            description: 'Discover outfits and wardrobe upgrades by uploading a photo. ' +
              'Our visual search feature makes fashion exploration easy and fun!'
        },
        Component: SearchByPhotoSection
    },
    {
        id: 'virtual-try-on',
        title: 'Virtual try-on',
        meta: {
            title: 'Virtual Fitting Room - Try Before You Buy!',
            description: 'Experience the future of fashion with our Virtual Try-On feature. ' +
              'See how clothes look on you before purchasing. Say goodbye to shopping anxiety!'
        },
        Component: VirtualTryOnSection
    },
    {
        id: 'fit-predictor',
        title: 'Fit predictor',
        meta: {
            title: 'Fit Predictor - Clothes Tailored Just for You',
            description: 'Discover the perfect fit with our Fit Predictor feature. ' +
              'Say goodbye to ill-fitting clothes and hello to personalized fashion that complements your body.'
        },
        Component: FitPredictorCard
    },
    {
        id: 'personalisation',
        title: 'Personalisation',
        meta: {
            title: 'Hyper-Personalization - Shopping, Tailored Just for You',
            description: 'Experience fashion tailored just for you. ' +
              'Our hyper-personalization feature combines your style, body measurements, and past purchases for a truly individual shopping experience.'
        },
        Component: PersonalisedSuggestionsScreen
    },
    {
        id: 'vision',
        title: 'Vision',
        meta: {
            title: 'Our Vision - Personalized, Sustainable, Inclusive, and Synergetic',
            description: 'Explore our vision for the future of fashion. ' +
              'Discover how we\'re committed to tailored personalization, sustainability, inclusivity, and brand synergy. ' +
              'Join us on this transformative journey.'
        },
        Component: VisionSection
    },
]

export default screens
