import React, {FC} from "react";
import PersonalisedSuggestionsDemo from "./PersonalisedSuggestionsDemo";

const PersonalisedSuggestionsScreen: FC<{ id: string }> = ({id}) => (
  <section id={id} className="flex snap-start lg:snap-center w-full px-5 shrink-0 px-20">

      <div className="max-w-max mx-auto lg:max-w-screen-2xl flex flex-col lg:grid lg:grid-cols-2 items-center space-y-5 lg:space-y-0 lg:space-x-10 xl:space-x-20">
          <div className="flex flex-col space-y-5">
          <h2 className="inline text-6xl 2xl:text-7xl  font-serif text-slate-700">Personalised suggestions</h2>
          <p className="text-sm xl:text-base text-slate-500">
              According to recent findings, there is a growing demand for an exceptionally personalized
              shopping journey. By harnessing the power of machine learning, we possess the ability to
              analyze a wealth of user data, encompassing their current wardrobe, style inclinations,
              and body measurements. This empowers us to provide personalized recommendations for
              outfits that align seamlessly with individual preferences.
          </p>
          <p className="text-sm xl:text-base text-slate-500">
              Our pioneering sales approach is committed to fostering customer confidence, assuring them
              that our platform is a one-stop destination for all their unique style needs. Moreover,
              each interaction with our platform guarantees a new and distinct array of fashion
              ensembles, ensuring a fresh and distinctive experience upon every visit.
          </p>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5">
              <p className="col-span-2 xl:col-span-1 inline-flex items-center space-x-3 bg-orange-50 p-5 rounded-3xl">
                  <span
                    className="inline-flex flex-col divide-y-4 divide-orange-400 text-4xl lg:text-5xl text-orange-400">
                      <span>4</span>
                      <span>5</span>
                  </span>
                  <span className="text-sm text-slate-500">of customers note lack of personalisation in fashion e-commerce</span>
              </p>
              <p className="inline-flex items-center space-x-3 p-5 bg-slate-100 rounded-3xl">
                  <span className="text-sm xl:text-base text-slate-500">Discover a shopping experience that feels like it's designed just for you.</span>
              </p>
              <p className="inline-flex items-center space-x-3 p-5 bg-slate-100 rounded-3xl">
                  <span className="text-sm xl:text-base text-slate-500">Step into the future of e-commerce, where every visit is a unique experience.</span>
              </p>
              <p
                className="col-span-2 inline-flex items-center justify-between space-x-3 p-5 rounded-3xl bg-slate-900">
                  <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">2.5</span>
                  <span className="text-sm text-orange-50">
                      times more likely a customer to make a purchase when presented with personalized recommendations
                  </span>
              </p>
          </div>
      </div>

          <div className="relative">
              <PersonalisedSuggestionsDemo/>
          </div>
      </div>

  </section>
)

export default PersonalisedSuggestionsScreen
