import {LambdaClient} from "@aws-sdk/client-lambda";
import config from "../config";

const lambdaClient = new LambdaClient({
    region: config.aws.region,
    credentials: {
        accessKeyId: config.aws.accessKeyId,
        secretAccessKey: config.aws.secretAccessKey,
    }
});

export default lambdaClient;
