import React, {FC, useEffect, useState} from "react";
import classNames from "../helpers/classNames";
import {SubscribeBanner} from "../components/SubscribeModal";
import screens from "../data/screens";


const Home: FC = () => {

    const [intersecting, setIntersecting] = useState<string>('home')

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
              entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                      setIntersecting(entry.target.id);
                  }
              });
          },
          {
              root: document.querySelector("#main"),
              rootMargin: "0px",
              threshold: 0.5
          }
        );

        const screens = document.querySelectorAll('main > *')

        screens.forEach((screen) => {
            observer.observe(screen);
        })

        return () => {
            screens.forEach((screen) => {
                observer.unobserve(screen);
            })
        };
    }, []);

    useEffect(() => {
        const screen = screens.find(({id}) => id == intersecting)

        if (screen) {
            document.title = screen.meta.title

            const meta = document.querySelector('meta[name="description"]')
            if (meta) {
                meta.setAttribute('content', screen.meta.description)
            }
        }
    }, [intersecting])

    return (
      <div className="h-screen flex min-h-full h-full bg-slate-50">
          <div className="flex flex-col">

              <main id="main"
                    className="flex flex-col lg:flex-row grow overflow-x-scroll space-y-10 snap-y lg:snap-x lg:snap-mandatory scroll-smooth scrollbar-none pt-5">
                  {
                      screens.map(({id, Component}, idx) => (
                        <Component id={id} key={idx}/>
                      ))
                  }
              </main>

              <header className="lg:block hidden">
                  <nav className="px-5 py-5">
                      <ul className="flex items-center justify-between">
                          {
                              screens.map(({id, title, Component}, idx) => (
                                <>
                                    <li key={idx} data-key={id}
                                        className={intersecting == id ? 'font-serif italic text-lg text-orange-400' : 'text-slate-500'}>
                                        <a href={`#${id}`}>{title}</a>
                                    </li>
                                    {
                                        idx < screens.length - 1 && (
                                          <span key={`${idx}-line`}
                                                className="grow border-b border-dashed border-slate-200"/>
                                        )
                                    }
                                </>
                              ))
                          }
                      </ul>
                  </nav>
              </header>
          </div>

          <header className="absolute flex flex-col lg:hidden h-full right-0">
              <nav className="h-full w-5 py-5">
                  <ul className="h-full flex flex-col items-center justify-between">
                      {
                          screens.map(({id, title, Component}, idx) => (
                            <>
                                <li key={idx}
                                    className={
                                        classNames(
                                          intersecting == id ? 'font-serif italic text-xs text-orange-400' : 'text-slate-500 text-xs',
                                          'rotate-90 whitespace-nowrap'
                                        )
                                    }>
                                    <a href={`#${id}`}>{title}</a>
                                </li>
                                {
                                    idx < screens.length - 1 && (
                                      <span key={`${idx}-line`}
                                            className="grow border-r border-dashed border-slate-200"/>
                                    )
                                }
                            </>
                          ))
                      }
                  </ul>
              </nav>
          </header>

          <SubscribeBanner/>
      </div>
    )
}


export default Home
