export type Product = { image: string, price: string, active?: boolean }

export type Category = { title: string, products: Product[] }

export const jackets: Product[] = [
    {
        image: 'images/demo/virtual-try-on/jackets/1.webp',
        price: '$ 219.00',
        active: true
    },
    {
        image: 'images/demo/virtual-try-on/jackets/2.webp',
        price: '$ 244.00'
    },
    {
        image: 'images/demo/virtual-try-on/jackets/3.webp',
        price: '$ 229.00'
    },
]

export const blouses: Product[] = [
    {
        image: 'images/demo/virtual-try-on/blouses/1.webp',
        price: '$ 119.00',
        active: true
    },
    {
        image: 'images/demo/virtual-try-on/blouses/2.webp',
        price: '$ 129.00',
    },
    {
        image: 'images/demo/virtual-try-on/blouses/3.webp',
        price: '$ 129.00',
    },
    {
        image: 'images/demo/virtual-try-on/blouses/4.webp',
        price: '$ 129.00',
    },
    {
        image: 'images/demo/virtual-try-on/blouses/5.webp',
        price: '$ 129.00',
    },
    {
        image: 'images/demo/virtual-try-on/blouses/6.webp',
        price: '$ 129.00',
    },
    {
        image: 'images/demo/virtual-try-on/blouses/7.webp',
        price: '$ 129.00',
    }
]

export const jeans: Product[] = [
    {
        image: 'images/demo/virtual-try-on/jeans/1.webp',
        price: '$ 219.00',
        active: true
    },
    {
        image: 'images/demo/virtual-try-on/jeans/2.webp',
        price: '$ 219.00'
    },
    {
        image: 'images/demo/virtual-try-on/jeans/3.webp',
        price: '$ 219.00'
    },
    {
        image: 'images/demo/virtual-try-on/jeans/4.webp',
        price: '$ 219.00'
    }
]

export const footwear: Product[] = [
    {
        image: 'images/demo/virtual-try-on/footwear/1.webp',
        price: '$ 219.00',
        active: true
    },
    {
        image: 'images/demo/virtual-try-on/footwear/2.webp',
        price: '$ 219.00'
    },
    {
        image: 'images/demo/virtual-try-on/footwear/3.webp',
        price: '$ 219.00'
    }
]

export const categories: Category[] = [
    {
        title: 'Outerwear',
        products: jackets
    },
    {
        title: 'Blouses',
        products: blouses
    },
    {
        title: 'Jeans',
        products: jeans
    },
    {
        title: 'Footwear',
        products: footwear
    }
]


