import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Listbox, Transition} from "@headlessui/react";
import React, {FC, Fragment, useEffect, useRef, useState} from "react";
import searchQueries, {Filter, Product, Query} from "../../../data/search-filters";
import classNames from "../../../helpers/classNames";
import {faChevronDown, faChevronUp, faMagnifyingGlass, faPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";

const SearchDemo: FC = () => {

    return (
      <>
          <img src="images/demo/search/preview.webp" className="block lg:hidden"/>
          <div className="relative hidden lg:flex flex-col h-full space-y-3">
              <Search/>
          </div>
      </>
    )
}

const Search: FC = () => {

    const [{filters, products}, setQuery] = useState<Query>(searchQueries[0])

    const valueInputRef = useRef<HTMLSpanElement>(null)
    const suggestionInputRef = useRef<HTMLSpanElement>(null)

    const [showFilters, setShowFilters] = useState<boolean | 'loading'>(false)

    const clear = () => {
        if (valueInputRef.current) valueInputRef.current.innerHTML = '';
        if (suggestionInputRef.current) suggestionInputRef.current.innerHTML = '';

        setShowFilters(false)
    }

    const type = async (ref: React.RefObject<HTMLSpanElement>, text: string, timeout: number = 0) => {
        if (!ref.current) return Promise.reject('Input not found');

        ref.current.innerHTML = ''

        for (let i = 0; i < text.length; i++) {
            ref.current.innerHTML += text.charAt(i);
            await new Promise((resolve) => setTimeout(resolve, 50));
        }
        return new Promise((resolve) => setTimeout(resolve, timeout));
    };

    const exec = (query: Query) => new Promise((resolve) => {
        setQuery(query)
        setShowFilters('loading')

        type(valueInputRef, query.query)
          .then(async () => {
              setTimeout(async () => {
                  for (const suggestion of query.suggestions) {
                      await type(suggestionInputRef, suggestion, 3000);
                  }
              }, 1000)
          })
          .then(() => setTimeout(() => setShowFilters(true), 1000))
          .then(() => setTimeout(resolve, (query.suggestions.length + 1) * 3000))

    })

    useEffect(() => {

        const loopValues = async () => {
            for (const query of searchQueries) {
                clear()
                await exec(query)
            }
        }

        loopValues()

    }, [])


    return (
      <>
          <div className="flex items-center rounded-xl shadow-sm bg-white space-x-2 px-4 py-2.5">
              <FontAwesomeIcon
                icon={showFilters == 'loading' ? faSpinner : faMagnifyingGlass}
                className={'h-5 w-5 text-slate-400 ' + (showFilters == 'loading' ? 'animate-spin' : '')}
              />
              <span ref={valueInputRef} className="blinking-cursor truncate inline-flex items-center text-slate-900"/>
              <span ref={suggestionInputRef} className="text-slate-500 truncate"/>
              <style>
                  {`
                  .blinking-cursor::after {
                      content: "";
                      width: 2px;
                      height: 18px;
                      background: #475569;
                      display: inline-block;
                      animation: cursor-blink 1s steps(2) infinite;
                  }
                  
                  @keyframes cursor-blink {
                      0% {
                        opacity: 0;
                      }
                  }
              `}
              </style>
          </div>

          <div className="grow flex flex-col">
              <div className={classNames(
                showFilters != true ? 'block' : 'hidden',
                'h-full w-full p-3 bg-slate-100 rounded-xl'
              )}/>

              <Transition
                show={showFilters == true}
                as={'div'}
                className="relative z-[11]"
                enter="transition-all ease-in duration-100"
                enterFrom="-translate-y-6 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-6 opacity-0"
              >
                  <div className="flex items-center space-x-3 overflow-x-auto">
                  {
                      filters.map((filter, idx) => {

                          const activeValues = filter.values
                            .filter((value) => !!value.active)
                            .map((value) => value.label)

                          if (!activeValues) return <></>

                          return (
                            <Listbox as="div" key={idx} multiple={true} defaultValue={activeValues}>
                                {({open}) => (
                                  <div className="static">
                                      <Listbox.Button
                                        className={
                                            classNames(
                                              'relative flex items-center space-x-5 w-full cursor-default rounded-xl py-1.5 px-3 text-left text-gray-900 shadow-sm focus:outline-none',
                                              filter.active ? 'bg-white' : 'bg-white/80 hover:bg-white'
                                            )
                                        }
                                      >
                                          <div className="flex flex-col">
                                              <span className="truncate text-xs text-slate-500">{filter.label}</span>
                                              <span className="truncate">{activeValues.join(', ')}</span>
                                          </div>
                                          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className="h-3 w-3 text-slate-400" aria-hidden="true"/>
                                      </Listbox.Button>
                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                          <Listbox.Options
                                            className="absolute z-10 mt-1 min-w-sm max-h-60 overflow-auto rounded-xl bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm"
                                          >
                                              {filter.values.map((value) => (
                                                <Listbox.Option
                                                  key={value.label}
                                                  value={value.label}
                                                  className={({active}) =>
                                                    classNames(
                                                      active ? 'bg-orange-400 text-white' : 'text-slate-900',
                                                      'relative cursor-default select-none py-1.5 pl-3 pr-9'
                                                    )
                                                  }
                                                >
                                                    {value.label}
                                                </Listbox.Option>
                                              ))}
                                          </Listbox.Options>
                                      </Transition>
                                  </div>
                                )}
                            </Listbox>
                          )
                      })
                  }
                  </div>
              </Transition>

          </div>

          <div className="relative grow flex flex-col overflow-y-scroll px-3 -mx-3 py-3 -my-3">
              <Transition
                show={showFilters == true}
                as={'div'}
                className="grid grid-cols-3 gap-3 z-[10]"
                enter="transition-all ease-in duration-100"
                enterFrom="-translate-y-6 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-6 opacity-0"
              >
                  {
                      products.map((product, idx) => <ProductCard key={idx} {...product}/>)
                  }
              </Transition>

              <div className={classNames(
                showFilters != true ? 'block' : 'hidden',
                'h-full w-full p-3 bg-slate-100 rounded-xl'
              )}/>
          </div>
      </>
    )
}

const ProductCard: FC<Product> = (product) => {

    return (
      <div className="snap-center flex flex-col shrink-0 space-y-3">
          <div
            className="pointer-events-auto p-3 bg-white rounded-xl z-[100] shadow-sm overflow-hidden hover:scale-105 transition-all duration-200">

              <img src={product.image}
                   className="w-full aspect-[9/12] object-contain drop-shadow hover:scale-150 hover:drop-shadow-lg transition-all duration-200 z-[-1] relative"/>

              <div className="w-full flex justify-between items-center z-[100]">
                  <span className="font-monospace text-sm">{product.price}</span>
                  <button
                    className="flex items-center justify-center space-x-1 font-semibold px-2 py-1 h-8 rounded-full">
                      <FontAwesomeIcon icon={faPlus} className="h-4 w-4 text-slate-700"/>
                  </button>
              </div>
          </div>

      </div>
    )
}

export default SearchDemo
