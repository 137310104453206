import React, {FC} from "react";
import SearchByPhotoDemo from "./SearchByPhotoDemo";

const SearchByPhotoSection: FC<{ id: string }> = ({id}) => (
  <section id={id} className="flex snap-start lg:snap-center w-full px-5 shrink-0 px-20">

      <div className="max-w-max mx-auto lg:max-w-screen-2xl flex flex-col lg:grid lg:grid-cols-2 items-center space-y-5 lg:space-y-0 space-x-10 xl:space-x-20">
          <div className="flex flex-col space-y-3 lg:space-y-5">
              <h2 className="inline text-6xl 2xl:text-7xl text-slate-700"><span
                className="text-orange-400 font-serif italic">Photo-</span>based search</h2>
              <p className="text-sm xl:text-base text-slate-500">
                  Experience the future of shopping with our <i className="font-semibold">search by
                  photo</i> feature. Just upload an
                  image, and our advanced AI instantly matches similar products. Effortless discovery at
                  your fingertips. Try it now and transform your shopping experience!
              </p>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5">
                  <p className="col-span-2 inline-flex flex-col p-5 bg-orange-50 rounded-3xl">
                      <span className="text-lg xl:text-2xl font-medium text-orange-400">Discover similar products</span>
                      <span className="text-sm text-slate-500">
                          Utilize our image-based search feature to find items that closely match your preferences. Upload photos, and let our AI technology identify products with a strong resemblance in our database.
                      </span>
                  </p>
                  <p className="row-span-2 col-span-2 lg:col-span-1 inline-flex flex-col p-5 bg-slate-100 rounded-3xl">
                      <span className="text-lg xl:text-2xl font-medium text-orange-400">Style enhancement</span>
                      <span className="text-sm text-slate-500">
                          Elevate your fashion game with our tool that helps you find clothing items that perfectly complement your chosen style. Our pre-trained AI simplifies wardrobe expansion to match your desired fashion aesthetics.
                      </span>
                  </p>
                  <p className="col-span-2 inline-flex items-center space-x-3 p-5 bg-slate-900 rounded-3xl">
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">70%</span>
                      <span className="text-sm xl:text-base text-orange-50">shoppers prefer using visual or AI-powered search over traditional category-based search</span>
                  </p>
              </div>
          </div>

          <div className="">
              <SearchByPhotoDemo/>
          </div>
      </div>

  </section>
)


export default SearchByPhotoSection
