export type Position = 'bottom' | 'top' | 'left' | 'right'

export type ProductFitConfig = {
    image: string,
    prediction: number,
    size: string,
    dot: {
        x: number,
        y: number,
        connect: Position
    }
    btn: {
        x: number,
        y: number,
        connect: Position
    }
}

export type Measurement = {
    title: string,
    image: string,
    minValue: number,
    maxValue: number,
    defaultValue: number
}

export const products: ProductFitConfig[] = [
    {
        image: 'images/demo/fit-predictor/blouse.webp',
        prediction: 97,
        size: 'M',
        dot: {
            x: 210,
            y: 120,
            connect: 'left'
        },
        btn: {
            x: 30,
            y: 150,
            connect: 'right'
        }
    },
    {
        image: 'images/demo/fit-predictor/trousers.webp',
        prediction: 99,
        size: '46 / M',
        dot: {
            x: 140,
            y: 400,
            connect: 'right'
        },
        btn: {
            x: 190,
            y: 270,
            connect: 'left'
        }
    },
]

const measurements: Measurement[] = [
    {
        title: 'Waist',
        image: 'images/demo/fit-predictor/woman-waist.webp',
        minValue: 30,
        maxValue: 160,
        defaultValue: 55
    },
    {
        title: 'Billy',
        image: 'images/demo/fit-predictor/woman-billy.webp',
        minValue: 30,
        maxValue: 160,
        defaultValue: 55
    },
    {
        title: 'Chest',
        image: 'images/demo/fit-predictor/woman-chest.webp',
        minValue: 30,
        maxValue: 160,
        defaultValue: 55
    },
    {
        title: 'Hips',
        image: 'images/demo/fit-predictor/woman-hips.webp',
        minValue: 30,
        maxValue: 160,
        defaultValue: 55
    },
    {
        title: 'Thigh',
        image: 'images/demo/fit-predictor/woman-thigh.webp',
        minValue: 20,
        maxValue: 55,
        defaultValue: 40
    },
    {
        title: 'Height',
        image: 'images/demo/fit-predictor/woman-height.webp',
        minValue: 130,
        maxValue: 220,
        defaultValue: 175
    },
]

export default measurements
