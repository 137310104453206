import React, {FC, useState} from "react";
import {categories, Product} from "../../../data/virtual-try-on-product-suggestions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleCheck, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Transition} from "@headlessui/react";

const VirtualTryOnDemo: FC = () => {

    const [activeCategory, setActiveCategory] = useState<number>(0)

    return (
      <>
          <img src="images/demo/virtual-try-on/preview.webp" className="block lg:hidden"/>
          <div className="relative hidden lg:grid grid-cols-2 h-128 w-full overflow-hidden">
              <div className="relative">
                  <div
                    className="absolute w-full h-full rounded-xl shadow-md bg-cover bg-center bg-no-repeat bg-[url('../public/images/demo/virtual-try-on/model.webp')]"/>
              </div>

              <div className="flex flex-col overflow-hidden space-y-3 pl-5">

                  <div className="flex snap-x overflow-x-scroll overflow-y-visible scrollbar-none space-x-3 pr-5">
                      {
                          categories.map((category, idx) => (
                            <button
                              key={idx}
                              className={
                                  'text-base text-left font-semibold px-3 py-1.5 border rounded-full ' +
                                  (activeCategory == idx ? 'bg-white text-slate-700' : 'border-slate-500 text-slate-500')
                              }
                              onClick={() => setActiveCategory(idx)}
                            >
                                {category.title}
                            </button>
                          ))
                      }
                  </div>
                  {
                      categories.map((category, idx) => (
                        <Transition
                          key={idx}
                          show={activeCategory == idx}
                          enter="transition-all delay-100 duration-300 ease-out"
                          enterFrom="transform scale-0 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                        >
                            <div className="flex snap-x overflow-x-scroll overflow-y-visible scrollbar-none space-x-3 pr-5">
                                {
                                    category.products.map((product, idx) => (
                                      <ProductCard {...product} key={idx}/>
                                    ))
                                }
                            </div>
                        </Transition>
                      ))
                  }
                  <div className="flex snap-x overflow-x-scroll overflow-y-visible scrollbar-none space-x-3 pr-5">
                      <button
                        className="flex items-center text-base text-left text-slate-500 hover:text-slate-700 font-semibold px-3 py-1.5 border border-slate-500 hover:border-slate-700 border-dashed rounded-full">
                          <FontAwesomeIcon icon={faPlus} className="mr-2 h-3 w-3"/>
                          <span>Footwear</span>
                      </button>
                      <button
                        className="flex items-center text-base text-left text-slate-500 hover:text-slate-700 font-semibold px-3 py-1.5 border border-slate-500 hover:border-slate-700 border-dashed rounded-full">
                          <FontAwesomeIcon icon={faPlus} className="mr-2 h-3 w-3"/>
                          <span>Bags</span>
                      </button>
                      <button
                        className="flex items-center text-base text-left text-slate-500 hover:text-slate-700 font-semibold px-3 py-1.5 border border-slate-500 hover:border-slate-700 border-dashed rounded-full">
                          <FontAwesomeIcon icon={faPlus} className="mr-2 h-3 w-3"/>
                          <span>Eyewear</span>
                      </button>
                      <button
                        className="flex items-center text-base text-left text-slate-500 hover:text-slate-700 font-semibold px-3 py-1.5 border border-slate-500 hover:border-slate-700 border-dashed rounded-full">
                          <FontAwesomeIcon icon={faPlus} className="mr-2 h-3 w-3"/>
                          <span>Accessories</span>
                      </button>
                  </div>

              </div>
          </div>
      </>
    )
}

const ProductCard: FC<Product & { active?: boolean }> = ({active = false, ...product}) => {

    return (
      <div className="snap-center flex flex-col shrink-0 space-y-3">
          <div
            className={
                'relative p-3 rounded-2xl z-[100] shadow-sm overflow-hidden duration-200 border-2' +
                (active ? 'border-teal-500 bg-white ' : 'border-white bg-white/70 backdrop-blur-sm')
            }
          >
              <button
                className={
                    'absolute flex items-center justify-center space-x-2 px-2 py-1 rounded-full border text-sm ' +
                    (active ? 'bg-slate-800 border-slate-800 text-white' : 'border-slate-600 text-slate-7800')
                }
              >
                  <FontAwesomeIcon icon={active ? faCircleCheck : faCheck} className="h-3 w-3"/>
                  <span>{active ? 'Take off' : 'Try on'}</span>
              </button>

              <img src={product.image}
                   className="w-36 aspect-[9/16] object-contain drop-shadow hover:scale-150 hover:drop-shadow-lg transition-all duration-200 z-[-1] relative"/>

              <div className="w-full flex justify-between items-center z-[100]">
                  <span className="font-monospace">{product.price}</span>
                  <button
                    className="flex items-center justify-center space-x-1 font-semibold px-2 py-1 h-8 rounded-full">
                      <FontAwesomeIcon icon={faTimes} className="h-4 w-4 text-slate-700"/>
                  </button>
              </div>
          </div>
      </div>
    )
}

export default VirtualTryOnDemo
