import React, {FC} from "react";
import SearchDemo from "./SearchDemo";

const SearchSection: FC<{ id: string }> = ({id}) => (
  <section id={id} className="flex snap-start lg:snap-center w-full px-5 shrink-0 px-20">

      <div className="max-w-max mx-auto lg:max-w-screen-2xl flex flex-col lg:grid lg:grid-cols-2 items-center space-y-5 lg:space-y-0 lg:space-x-10 xl:space-x-20">
          <div className="flex flex-col space-y-5">
              <h2 className="font-serif text-6xl 2xl:text-7xl text-slate-700">Search. Supercharged.</h2>
              <p className="text-sm xl:text-base text-slate-500">
                  Traditional text-based fashion searches often fall short, as lengthy queries like 'slim
                  fit teared jeans' or 'relaxed fit plain white t-shirt' may not align perfectly with
                  product names in e-commerce databases. This leads to customer frustration, requiring
                  meticulous category exploration and filter application. Our solution utilizes AI to
                  introduce advanced filters that adapt to complex user queries, streamlining the search
                  process.
              </p>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                  <p className="col-span-2 inline-flex items-center space-x-3 p-5 bg-orange-50 rounded-3xl">
                      <span className="-rotate-90 text-sm xl:text-base text-slate-500">only</span>
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">16%</span>
                      <span className="text-sm xl:text-base text-slate-500">of e-commerce platforms deliver a filtering experience that meet customer expectations</span>
                  </p>
                  <p className="inline-flex items-center space-x-3 p-5 bg-slate-100 rounded-3xl">
                      <span className="text-sm xl:text-base text-slate-500">Discover products effortlessly using natural language queries, and find what you love.</span>
                  </p>
                  <p className="inline-flex items-center space-x-3 p-5 bg-slate-100 rounded-3xl">
                      <span className="text-sm xl:text-base text-slate-500">Find products faster with AI that understands your preferences and adapts to your unique queries.</span>
                  </p>
                  <p className="col-span-2 inline-flex items-center space-x-3 p-5 bg-slate-900 rounded-3xl">
                      <span className="text-4xl lg:text-6xl xl:text-8xl text-orange-400">40%</span>
                      <span className="text-sm xl:text-base text-orange-50">increase in customer satisfaction reported by businesses who are using advanced AI search</span>
                  </p>
              </div>
          </div>

          <div className="flex flex-col lg:h-[600px]">
              <SearchDemo/>
          </div>
      </div>

  </section>
)

export default SearchSection
